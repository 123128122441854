
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../atoms/Input/Input.vue';
import Dropdown from '../../../../atoms/Dropdown/Dropdown.vue';
import Button from '../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../atoms/Modal/Modal.vue';
import { FormBuilder, FormControl } from '../../../../../utilities/Forms';
import { Validators } from '../../../../../utilities/Validators/Validators';
import { IDropdownOption } from '../../../../../utilities/Interfaces/form.interfaces';
import DatePicker from '@/atomic/molecules/DatePicker/DatePicker.vue';
import Checkbox from '@/atomic/atoms/Checkbox/Checkbox.vue';
import MultiSelectDropdown from '@/atomic/molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import MultiSelectInput from '@/atomic/molecules/Multi-SelectInput/MultiSelectInput.vue';

export default defineComponent({
  changesMade: {
    deep: true,
    handler() {
      this.$emit('hasChangesMade', this.changesMade);
    },
  },
  components: {
    'arc-input': Input,
    'arc-dropdown': Dropdown,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-date-picker': DatePicker,
    'arc-checkbox': Checkbox,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-multi-select-input': MultiSelectInput,
  },

  computed: {
    ...mapGetters(['modalData', 'user', 'access', 'permissions', 'brandOptions', 'mallOptions']),
    updateDisabled(): boolean {
      return !this.access?.superAdminAccess;
    },
    deleteDisabled(): boolean {
      return !this.access?.superAdminAccess;
    },
  },

  watch: {
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
    brandOptions: {
      deep: true,
      handler() {
        this.brandOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.brands.map((el: any) => el.brandId).includes(item?.value)) {
            this.brandValues.push(item);
          }
        });
      },
    },
    mallOptions: {
      deep: true,
      handler() {
        this.mallOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.malls.map((el: any) => el.mallId).includes(item?.value)) {
            this.mallValues.push(item);
          }
        });
      },
    },
  },

  async mounted(): Promise<void> {
    if (this.modalData?.notificationId) {
      this.modalData.row = await this.fetchNotification({ notificationId: this.modalData?.notificationId });
    }
    this.loadBrands();
    this.loadMallOptions();
    this.setForm();
    this.loaded = true;
  },
  data(): {
    changesMade: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    saving: boolean;
    deleting: boolean;
    formData: any;
    loaded: boolean;
    notificationTypeValue: IDropdownOption | null;
    notificationTypeOptions: IDropdownOption[];
    brandValues: IDropdownOption[];
    mallValues: IDropdownOption[];
    frequencyValue: IDropdownOption | null;
    frequencyOptions: IDropdownOption[];
    byDaysValues: IDropdownOption[];
    byDaysOptions: IDropdownOption[];
    initialLoad: boolean;
  } {
    return {
      changesMade: false,
      formValid: true,
      promptOpen: false,
      promptType: 'save',
      saving: false,
      deleting: false,
      formData: null,
      loaded: false,
      notificationTypeValue: null,
      notificationTypeOptions: [
        { description: 'Global', value: 1 },
        { description: 'Deadline', value: 2 },
        { description: 'Holiday', value: 3 },
        { description: 'Free Opportunity', value: 4 },
        { description: 'Paid Opportunity', value: 5 },
      ],
      brandValues: [],
      mallValues: [],
      frequencyValue: { description: 'Daily', value: 'daily' },
      frequencyOptions: [
        { description: 'Daily', value: 'daily' },
        { description: 'Weekly', value: 'weekly' },
        { description: 'Monthly', value: 'monthly' },
        { description: 'Yearly', value: 'yearly' },
      ],
      byDaysValues: [],
      byDaysOptions: [
        { description: 'Sunday', value: 'SU' },
        { description: 'Monday', value: 'MO' },
        { description: 'Tuesday', value: 'TU' },
        { description: 'Wednesday', value: 'WE' },
        { description: 'Thursday', value: 'TH' },
        { description: 'Friday', value: 'FR' },
        { description: 'Saturday', value: 'SA' },
      ],
      initialLoad: true,
    };
  },

  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'saveNotification',
      'updateNotification',
      'deleteNotification',
      'fetchNotification',
      'loadBrands',
      'loadMallOptions',
    ]),
    setForm(): void {
      let title = '';
      let description = '';
      let startDate = '';
      let endDate = '';
      let notificationType = '';
      let brands = [];
      let malls = [];
      let recurring = false;
      let frequency = 'daily';
      let byDays: any[] = [];
      let bySetPosition = '';
      let interval = 1;
      let count = '';

      // set values if editing existing notification
      if (this.modalData?.row) {
        title = this.modalData?.row?.title;
        description = this.modalData?.row?.description;
        startDate = this.modalData?.row?.startDate;
        endDate = this.modalData?.row?.endDate;
        notificationType = this.modalData?.row?.notificationTypeId;
        brands = this.modalData?.row?.brands;
        malls = this.modalData?.row?.malls;
        recurring = this.modalData?.row?.isRecurring;
        frequency = this.modalData?.row?.frequency;
        byDays = this.modalData?.row?.byWeekDay.split(',');
        bySetPosition = this.modalData?.row?.bySetPosition;
        interval = this.modalData?.row?.interval;
        count = this.modalData?.row?.count;
        this.notificationTypeOptions.forEach((item: IDropdownOption) => {
          if (notificationType === item?.value) {
            this.notificationTypeValue = item;
          }
        });
        this.frequencyOptions.forEach((item: IDropdownOption) => {
          if (frequency === item?.value) {
            this.frequencyValue = item;
          }
        });
      }
      this.formData = new FormBuilder({
        title: new FormControl(title, [Validators.required]),
        description: new FormControl(description, [Validators.required]),
        startDate: new FormControl(startDate, [Validators.required]),
        endDate: new FormControl(endDate),
        notificationType: new FormControl(notificationType, [Validators.required]),
        brands: new FormControl(brands),
        malls: new FormControl(malls),
        recurring: new FormControl(recurring),
        frequency: new FormControl(frequency),
        byDays: new FormControl(byDays),
        bySetPosition: new FormControl(bySetPosition),
        interval: new FormControl(interval),
        count: new FormControl(count),
      });
      setTimeout(() => {
        this.formData.controls.startDate.value = startDate;
        this.formData.controls.endDate.value = endDate;
        this.formData.controls.brands.value = this.brandValues;
        this.formData.controls.malls.value = this.mallValues;
        this.formData.controls.byDays.value = this.byDaysValues;

        this.byDaysOptions.forEach((item: IDropdownOption) => {
          if (byDays.includes(item?.value)) {
            this.byDaysValues.push(item);
          }
        });
      }, 1000);
      setTimeout(() => {
        this.initialLoad = false;
      }, 1500);
    },
    updateNotificationType(value: IDropdownOption) {
      this.notificationTypeValue = value;
      this.formData.controls.notificationType.value = this.notificationTypeValue.value;
      this.setChangesMade();
    },
    updateBrands(value: IDropdownOption[]) {
      this.brandValues = value;
      this.formData.controls.brands.value = this.brandValues;
      this.setChangesMade();
    },
    updateMalls(value: IDropdownOption[]) {
      this.mallValues = value;
      this.formData.controls.malls.value = this.mallValues;
      this.setChangesMade();
    },
    updateFrequency(value: IDropdownOption) {
      this.frequencyValue = value;
      this.formData.controls.frequency.value = this.frequencyValue.value;
      this.setChangesMade();
    },
    updateByDays(value: IDropdownOption[]) {
      this.byDaysValues = value;
      this.formData.controls.byDays.value = this.byDaysValues;
      this.setChangesMade();
    },
    toggleRecurring() {
      this.formData.controls.recurring.value = !this.formData.controls.recurring.value;
      if (this.formData.controls.recurring.value) {
        this.formData.controls.frequency.validators = [Validators.required];
      } else {
        this.formData.controls.frequency.validators = [];
      }
      this.setChangesMade();
    },
    setChangesMade() {
      if (!this.initialLoad) {
        this.changesMade = true;
      }
    },
    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade();
        }
      }, 0);
    },
    closePrompt(): void {
      this.promptOpen = false;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    async delete(): Promise<void> {
      this.deleting = true;
      this.promptOpen = false;

      try {
        await this.deleteNotification({ notificationId: this.modalData?.row?.notificationId });
        this.promptOpen = false;
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
          this.$notify({
            title: 'SUCCESS',
            text: 'Notification deleted successfully!',
            type: 'success',
            duration: 5000,
          });
        }, 500);
      } catch (err) {
        this.promptOpen = false;
        this.$notify({
          title: 'ERROR',
          text: 'Unable to delete notification.',
          type: 'error',
          duration: 5000,
        });
      } finally {
        this.deleting = false;
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    async save(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        let payload = {
          title: this.formData?.controls?.title?.value,
          description: this.formData?.controls?.description?.value,
          startDate: this.formData?.controls?.startDate?.value,
          endDate: this.formData?.controls?.endDate?.value === '' ? null : this.formData?.controls?.endDate?.value,
          notificationTypeId: this.formData?.controls?.notificationType?.value,
          brandIds: this.formData?.controls?.brands?.value.map((el: any) => el.value),
          mallIds: this.formData?.controls?.malls?.value.map((el: any) => el.value),
          isRecurring: this.formData?.controls?.recurring?.value,
          frequency: this.formData?.controls?.frequency?.value,
          byWeekDay: this.formData?.controls?.byDays?.value.map((el: any) => el.value).toString(),
          bySetPosition: this.formData?.controls?.bySetPosition?.value,
          interval: this.formData?.controls?.interval?.value,
          count: this.formData?.controls?.count?.value,
        };

        this.saving = true;

        try {
          await this.saveNotification(payload);
          this.$notify({
            title: 'SUCCESS',
            text: 'Notification saved successfully!',
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          const error: any = err;
          this.$notify({
            title: 'ERROR',
            text: error === 'There is already a Notification associated with that name.' ? error : 'Unable to create Notification',
            type: 'error',
            duration: 5000,
          });
        } finally {
          this.saving = false;
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Notification form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        let payload = {
          title: this.formData?.controls?.title?.value,
          description: this.formData?.controls?.description?.value,
          startDate: this.formData?.controls?.startDate?.value,
          endDate: this.formData?.controls?.endDate?.value === '' ? null : this.formData?.controls?.endDate?.value,
          notificationTypeId: this.formData?.controls?.notificationType?.value,
          brandIds: this.formData?.controls?.brands?.value.map((el: any) => el.value),
          mallIds: this.formData?.controls?.malls?.value.map((el: any) => el.value),
          isRecurring: this.formData?.controls?.recurring?.value,
          frequency: this.formData?.controls?.frequency?.value,
          byWeekDay: this.formData?.controls?.byDays?.value.map((el: any) => el.value).toString(),
          bySetPosition: this.formData?.controls?.bySetPosition?.value,
          interval: this.formData?.controls?.interval?.value,
          count: this.formData?.controls?.count?.value,
        };

        this.saving = true;

        try {
          await this.updateNotification({
            payload,
            notificationId: this.modalData?.row?.notificationId,
          });
          this.$notify({
            title: 'SUCCESS',
            text: 'Notification updated successfully!',
            type: 'success',
            duration: 5000,
          });
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          const error: any = err;
          this.$notify({
            title: 'ERROR',
            text: error === 'There is already a Notification associated with that name.' ? error : 'Unable to update Notification',
            type: 'error',
            duration: 5000,
          });
        } finally {
          this.saving = false;
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Notification form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
  },
});
