
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../../atoms/Input/Input.vue';
import Search from '../../../../../atoms/Search/Search.vue';
import Dropdown from '../../../../../atoms/Dropdown/Dropdown.vue';
import MultiSelectDropdown from '../../../../../molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import TextArea from '../../../../../atoms/Text-Area/Text-Area.vue';
import Checkbox from '../../../../../atoms/Checkbox/Checkbox.vue';
import Button from '../../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../../atoms/Modal/Modal.vue';
import RecommendedImages from '../../../../../molecules/Recommended-Images/Recommended-Images.vue';
import DatePicker from '../../../../../molecules/DatePicker/DatePicker.vue';
import LocationSelector from '../../../../../molecules/Locations-Selector/Location-Selector.vue';
import LanguageSelector from '../../../../../molecules/Language-Selector/Language-Selector.vue';
import SingleSelectInputSuggestion from '../../../../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import Carousel from '../../../../../molecules/Carousel/Carousel.vue';
import ImageUploader from '../../../../../organisms/Image-Uploader/Image-Uploader.vue';
import { ILanguage, IMallImageRequirement, IStore, ITag } from '../../../../../../../../../interfaces/src/v2/index';
import { FormBuilder, FormControl } from '../../../../../../utilities/Forms';
import { Validators } from '../../../../../../utilities/Validators/Validators';
import jimp from 'jimp';
import { dateFormatter } from '../../../../../../utilities/Functions/formatting';
import Jimp from 'jimp';
import { IDropdownOption } from '../../../../../../utilities/Interfaces/form.interfaces';
import Loader from '../../../../../atoms/Loader/Loader.vue';
import HttpService from '../../../../../../services/HttpService';
import { SocketUtility } from '../../../../../../utilities/Sockets/SocketUtility';
import SocketService from '../../../../../../services/SocketService';
import { SocketEvent } from '../../../../../../enums/SocketEvent';
import { CommunicationTypes } from '@/enums/CommunicationTypes';
import { IANA_TIMEZONES } from '../../../../../../utilities/Interfaces/timezone.interface';
import dayjs from 'dayjs';
import { Status } from '@/enums/Status';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import MultiSelectInput from '@/atomic/molecules/Multi-SelectInput/MultiSelectInput.vue';
import { Role } from '@/enums/Role';
import { AbortHandler } from '@/services/AbortHandler';

dayjs.extend(timezone);
dayjs.extend(utc);

interface IUploadedImage {
  type: any;
  orientation: 'portrait' | 'landscape' | 'square';
  isMaster: boolean;
  language: string;
  languageId?: number;
  height: number;
  width: number;
  aspectRatio: string;
  url: string;
  updatedAt?: any;
}

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-search': Search,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-text-area': TextArea,
    'arc-checkbox': Checkbox,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-recommended-images': RecommendedImages,
    'arc-image-uploader': ImageUploader,
    'arc-language-selector': LanguageSelector,
    'arc-date-picker': DatePicker,
    'arc-carousel': Carousel,
    'arc-location-selector': LocationSelector,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-loader': Loader,
    'arc-multi-select-input': MultiSelectInput,
  },

  computed: {
    Status() {
      return Status;
    },
    ...mapGetters([
      'modalData',
      'user',
      'access',
      'permissions',
      'jobTypes',
      'selectedRecruitmentCampaign',
      'companyOptions',
      'userOptions',
      'brandOptions',
      'brandTagOptions',
      'storeTagOptions',
      'storeOptions',
      'loadingStores',
      'firstDay',
      'lastDay',
      'channels',
    ]),
    filteredLocations(): any {
      // set all location options
      let locations = this.storeOptions;

      const campaignTypeFilter: any[] = [];
      locations?.forEach((location: any) => {
        location?.mall?.communicationTypes?.forEach((commType: any) => {
          if (commType?.communicationTypeId === CommunicationTypes['Recruitment Campaign']) {
            campaignTypeFilter.push(location);
          }
        });
      });
      locations = campaignTypeFilter;
      let tagCount: any = this.tagValues?.length;
      // filter tags
      if (tagCount > 0) {
        const tagIds = this.tagValues?.map((tag: any) => tag.value);
        // filter locations by tags
        locations = locations.filter((location: any) => {
          const locationTags = location?.tags?.map((tag: any) => tag.tagId);
          if (this.tagComboType.value === 'or') {
            return tagIds?.some((lt: number) => locationTags?.includes(lt));
          } else if (this.tagComboType.value === 'and') {
            return tagIds?.every((lt: number) => locationTags?.includes(lt));
          }
        });
      }

      // filter search params
      if (this.searchFilter !== '') {
        let searchResults: any[] = [];
        locations.forEach((element: any) => {
          let matchFound = false;
          if (typeof Object.values(element) === 'string') {
            if (Object.values(element).includes(this.searchFilter)) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'number') {
            if (
              Object.values(element)
                .toString()
                .includes(this.searchFilter)
            ) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'object' && Object.values(element)) {
            Object.values(element)
              .filter((value) => value)
              .forEach((nestedElement: any) => {
                if (typeof nestedElement === 'string') {
                  if (nestedElement.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'number') {
                  if (nestedElement.toString().includes(this.searchFilter)) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'object' && nestedElement) {
                  Object.values(nestedElement)
                    .filter((value) => value)
                    .forEach((nestedElementL2: any) => {
                      if (typeof nestedElementL2 === 'string') {
                        if (nestedElementL2.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'number') {
                        if (nestedElementL2.toString().includes(this.searchFilter)) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'object' && nestedElementL2) {
                        Object.values(nestedElementL2)
                          .filter((value) => value)
                          .forEach((nestedElementL3: any) => {
                            if (typeof nestedElementL3 === 'string') {
                              if (nestedElementL3.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                                matchFound = true;
                              }
                            }
                            if (typeof nestedElementL3 === 'number') {
                              if (nestedElementL3.toString().includes(this.searchFilter)) {
                                matchFound = true;
                              }
                            }
                          });
                      }
                    });
                }
              });
          }
          if (matchFound) {
            searchResults.push(element);
          }
        });
        locations = searchResults;
      }
      locations = locations?.sort((a: IStore, b: IStore) =>
        a.storeNumber.toString().localeCompare(b.storeNumber.toString(), undefined, { numeric: true }),
      );
      return locations;
    },
    filteredLocationsAvailable(): any {
      return this.filteredLocations.filter((location: any) => !this.selectedLocationIds?.includes(location?.storeId));
    },
    filteredLocationsSelected(): any {
      return this.filteredLocations.filter((location: any) => this.selectedLocationIds?.includes(location?.storeId));
    },
    updateDisabled(): boolean {
      const userStoreIds = this.user?.stores.map((store: any) => store.storeId);
      let ds = false;
      if (userStoreIds?.length > 0) {
        if (this.formData && this.formData?.controls?.locations?.value?.length > 0) {
          this.formData?.controls?.locations?.value?.forEach((store: IStore) => {
            if (!userStoreIds.includes(store.storeId)) {
              ds = true;
            }
          });
        }
        if (
          (this.permissions &&
            this.modalData?.row &&
            !this.permissions?.canUpdateAllRecruitmentCampaigns &&
            (!this.permissions?.canUpdateOwnedRecruitmentCampaigns ||
              (this.permissions?.canUpdateOwnedRecruitmentCampaigns && this.user?.userId !== this.selectedRecruitmentCampaign?.createdByUserId))) ||
          ds
        ) {
          if (this.initialLoad) {
            this.$notify({
              title: 'UPDATE PERMISSIONS DISABLED',
              text: 'There are locations in this campaign that you do not have permission to update.',
              type: 'warn',
              duration: 5000,
            });
          }
        }
      }
      return (
        (this.permissions &&
          this.modalData?.row &&
          !this.permissions?.canUpdateAllRecruitmentCampaigns &&
          (!this.permissions?.canUpdateOwnedRecruitmentCampaigns ||
            (this.permissions?.canUpdateOwnedRecruitmentCampaigns && this.user?.userId !== this.selectedRecruitmentCampaign?.createdByUserId))) ||
        ds
      );
    },
    deleteDisabled(): boolean {
      const userStoreIds = this.user?.stores.map((store: any) => store.storeId);
      let ds = false;
      if (userStoreIds?.length > 0) {
        if (this.formData && this.formData?.controls?.locations?.value?.length > 0) {
          this.formData?.controls?.locations?.value?.forEach((store: IStore) => {
            if (!userStoreIds.includes(store.storeId)) {
              ds = true;
            }
          });
        }
      }
      return (
        (this.permissions &&
          this.modalData?.row &&
          !this.permissions?.canDeleteAllRecruitmentCampaigns &&
          (!this.permissions?.canDeleteOwnedRecruitmentCampaigns ||
            (this.permissions?.canDeleteOwnedRecruitmentCampaigns && this.user?.userId !== this.selectedRecruitmentCampaign?.createdByUserId))) ||
        ds
      );
    },
    duplicateDisabled(): boolean {
      let oldEaExists = false;
      const creationDate = new Date(this.campaign?.createdAt);
      const ea3Date = new Date('June 06, 2022');
      if (creationDate < ea3Date) {
        oldEaExists = true;
      }
      return oldEaExists;
    },
    notes(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.notes?.value, this.activeLanguage?.isoCode);
    },
    headline(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.headline.value, this.activeLanguage?.isoCode);
    },
    description(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.description.value, this.activeLanguage?.isoCode);
    },
    twitterDesc(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.twitterDesc.value, this.activeLanguage?.isoCode);
    },
    instagramDesc(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.instagramDesc.value, this.activeLanguage?.isoCode);
    },
    facebookDesc(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.facebookDesc.value, this.activeLanguage?.isoCode);
    },
    brandText(): string {
      const brand: any = this.brandOptions.filter((i: any) => {
        return i.value === `${this.formData?.controls?.brand?.value}`;
      });
      return brand[0]?.title === 'Select' ? '' : brand[0]?.title;
    },
    containerType(): string {
      if (this.permissions?.superAdminAccess) {
        return 'admin-container';
      } else if (this.isMallAdmin()) {
        return 'mall-admin-container';
      } else {
        return 'user-container';
      }
    },
    contentContainerStyle(): string {
      return this.previewOpen ? 'width: 70%;' : 'width: 100%;';
    },
    previewContainerStyle(): string {
      return this.previewOpen
        ? 'width: 30%; border-left: 1px solid #D6D6D6; opacity: 1; margin: 2rem 0; padding: 0 0 0 2rem;'
        : 'width: 0%; opacity: 0;';
    },
    imageDescription(): string {
      return 'Please note that it could take several minutes for the images to generate all required sizes. Our image logic works as follows: Uploading multiple images at once will remove any duplicate image sizes, and always use the closest uploaded image size when generating a required image. If you add or remove an image, all generated images will be regenerated using the new set of uploaded images. If a new uploaded image is exactly the same size as an existing uploaded image, it will take it\'s place and all generated images that used the existing image will be regenerated. Uploaded images can only generate images smaller than itself, so make sure you upload an image equal to or greater than the size of the biggest required image of it\'s respective orientation. You can remove any uploaded images, and when you do the generated images will regenerate, replacing any generated images that were using said image if there is another larger uploaded image with the same orientation, other wise the generated image is just removed.';
    },
    duplicationTooltipText(): string {
      return this.duplicating ? 'Duplicating' : 'Nothing to Duplicate';
    },
    selectCompanyPlaceholder(): string {
      return this.brandOptions && this.brandOptions.length > 0 ? 'Select' : 'Select Company First';
    },
    imageSizeRequirements(): { square: string; landscape: string; portrait: string } {
      const statuses = {
        multi: '',
        square: 'Not Required',
        landscape: 'Not Required',
        portrait: 'Not Required',
      };

      const masterSquareImage = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height === el.width)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
      const masterPortraitImage = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height > el.width)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
      const masterLandscapeImage = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height < el.width)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.width - a.width)[0];

      if (masterSquareImage) {
        statuses.square = `${masterSquareImage.width}w x ${masterSquareImage.height}h or larger recommended`;
      }

      if (masterPortraitImage) {
        statuses.portrait = `${masterPortraitImage.width}w x ${masterPortraitImage.height}h or larger recommended`;
      }

      if (masterLandscapeImage) {
        statuses.landscape = `${masterLandscapeImage.width}w x ${masterLandscapeImage.height}h or larger recommended`;
      }

      return statuses;
    },
    primaryImageStatus(): {
      square: {
        required: boolean;
        uploaded: boolean;
      };
      landscape: {
        required: boolean;
        uploaded: boolean;
      };
      portrait: {
        required: boolean;
        uploaded: boolean;
      };
    } {
      let status = {
        square: {
          required: false,
          uploaded: false,
        },
        landscape: {
          required: false,
          uploaded: false,
        },
        portrait: {
          required: false,
          uploaded: false,
        },
      };
      this.uploadedPrimaryImages?.forEach((image: any) => {
        if (image?.orientation === 'square' && image?.isMaster && image?.language === this.activeLanguage?.isoCode) {
          status.square.uploaded = true;
        } else if (image?.orientation === 'landscape' && image?.isMaster && image?.language === this.activeLanguage?.isoCode) {
          status.landscape.uploaded = true;
        } else if (image?.orientation === 'portrait' && image?.isMaster && image?.language === this.activeLanguage?.isoCode) {
          status.portrait.uploaded = true;
        }
      });

      return status;
    },
    uploadedPrimaryImages(): IUploadedImage[] {
      let uploadedImages;
      let sorted;
      if (this.uploadedImages && this.uploadedImages.length > 0) {
        uploadedImages = [...this.uploadedImages];
      }

      // filter list to have only the largest image of each unique aspect ratio

      const sortingFunction = (a: IUploadedImage, b: IUploadedImage) => {
        const mappings = {
          aIsMaster: a.isMaster,
          bIsMaster: b.isMaster,
          aIsGenerated: !a.isMaster,
          bIsGenerated: !b.isMaster,
          aIsUploaded: !a.isMaster && a.isMaster,
          bIsUploaded: !b.isMaster && b.isMaster,
        };

        if (mappings.bIsMaster) {
          return 1;
        }

        if (mappings.aIsMaster && mappings.bIsUploaded) {
          return 0;
        }

        if (mappings.bIsUploaded) {
          return 1;
        }

        if (mappings.bIsGenerated) {
          return -1;
        }

        return -1;
      };

      let sortedSquareImages;
      let sortedLandscapeImages;
      let sortedPortraitImages;

      if (uploadedImages && uploadedImages.length > 0) {
        sortedSquareImages = uploadedImages
          .filter(
            (image: IUploadedImage) =>
              image.orientation === 'square' &&
              (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode),
          )
          .sort(sortingFunction);
        sortedLandscapeImages = uploadedImages
          .filter(
            (image: IUploadedImage) =>
              image.orientation === 'landscape' &&
              (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode),
          )
          .sort(sortingFunction);
        sortedPortraitImages = uploadedImages
          .filter(
            (image: IUploadedImage) =>
              image.orientation === 'portrait' &&
              (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode),
          )
          .sort(sortingFunction);
        sorted = [...sortedSquareImages, ...sortedLandscapeImages, ...sortedPortraitImages];
      }

      return sorted as IUploadedImage[];
    },
    searchLocationsStyle(): string {
      return this.storeTagOptions?.length > 0 ? 'grid-column-end: span 15;' : 'grid-column-end: span 30;';
    },
  },

  watch: {
    'formData.controls.locations.value': {
      deep: true,
      async handler(locations) {
        this.extractDataFromLocations(locations);
      },
    },
    selectedLocationIds: {
      handler(newSelectedLocationIds, oldSelectedLocationIds) {
        if (
          !this.initialLoad &&
          this.formData?.controls?.images?.value?.length > 0 &&
          this.formData?.controls?.locations?.value?.length > 0 &&
          newSelectedLocationIds.length !== oldSelectedLocationIds.length
        ) {
          this.imageResizeRequired = true;
        }
      },
    },
    'formData.controls.publishDate.value': {
      deep: true,
      handler() {
        if (this.formData?.controls?.publishDate?.value && this.formData?.controls?.endDate?.value && this.canCheckDates) {
          let d1: any = new Date(this.formData?.controls?.publishDate?.value);
          let d2: any = new Date(this.formData?.controls?.endDate?.value);
          if (this.daysBetween(d1, d2) >= 30) {
            this.$notify({
              title: 'CHECK YOUR DATES',
              text:
                'We noticed the time between your Start Date and End Date are greater than one month.  While you can submit a campaign for any length, as a courtesy, if your campaign is supposed to be less than one month, please re-check your dates entered.  If your dates are correct, please continue.',
              type: 'warn',
              duration: 11000,
            });
          }
        }
      },
    },
    'formData.controls.endDate.value': {
      deep: true,
      handler() {
        if (this.formData?.controls?.publishDate?.value && this.formData?.controls?.endDate?.value && this.canCheckDates) {
          let d1: any = new Date(this.formData?.controls?.publishDate?.value);
          let d2: any = new Date(this.formData?.controls?.endDate?.value);
          if (this.campaign?.statusId === 2 && this.isToday(d2)) {
            this.$notify({
              title: 'EXPIRING CAMPAIGN',
              text:
                'If you would like to expire the campaign now, please click the expire button below. If you would like the campaign to expire EOD, please click the udpate button below when you are finished your updates.',
              type: 'warn',
              duration: 15000,
            });
          }
          if (this.daysBetween(d1, d2) >= 30) {
            this.$notify({
              title: 'CHECK YOUR DATES',
              text:
                'We noticed the time between your Start Date and End Date are greater than one month.  While you can submit a campaign for any length, as a courtesy, if your campaign is supposed to be less than one month, please re-check your dates entered.  If your dates are correct, please continue.',
              type: 'warn',
              duration: 15000,
            });
          }
        }
      },
    },
    backgroundBlurry: {
      deep: true,
      async handler() {
        if (!this.initialLoad) {
          this.imageResizeRequired = true;
        }
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
    socialContacts: {
      deep: true,
      handler() {
        if (this.formData) {
          if (this.socialContacts) {
            this.formData.controls.twitterDesc.validators = [Validators.required];
            this.formData.controls.instagramDesc.validators = [Validators.required];
            this.formData.controls.facebookDesc.validators = [Validators.required];
          } else {
            this.formData.controls.twitterDesc.validators = [];
            this.formData.controls.instagramDesc.validators = [];
            this.formData.controls.facebookDesc.validators = [];
          }
        }
        if (this.attemptedSubmit) {
          this.formData.markAllAsTouched();
          this.validateControl('headline');
          this.validateControl('description');
          this.validateControl('twitterDesc');
          this.validateControl('instagramDesc');
          this.validateControl('facebookDesc');
          this.checkLanguageErrors();
        }
      },
    },
    startASAP: {
      deep: true,
      handler() {
        if (this.formData) {
          if (this.startASAP) {
            this.formData.controls.startDate.validators = [];
          } else {
            this.formData.controls.startDate.validators = [Validators.required];
          }
        }
      },
    },
    companyOptions: {
      deep: true,
      handler() {
        this.allowShowOptions = true;
        this.companyOptions.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.company?.value
              ? this.formData?.controls?.company?.value === item?.value
              : this.selectedRecruitmentCampaign?.companyId === item?.value
          ) {
            this.updateCompany(item);
          }
        });
      },
    },
    brandOptions: {
      deep: true,
      handler() {
        this.brandOptions.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.brand?.value
              ? this.formData?.controls?.brand?.value === item?.value
              : this.selectedRecruitmentCampaign?.brandId === item?.value
          ) {
            this.brandValue = item;
            this.formData.controls.brand.value = this.brandValue?.value;
            this.loadBrandTags({ brandId: this.formData.controls.brand.value });
            this.loadStoreTags({ brandIds: [this.formData.controls.brand.value] });
          }
        });
      },
    },
    userOptions: {
      deep: true,
      handler() {
        this.userOptions.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.user?.value
              ? this.formData?.controls?.user?.value === item?.value
              : this.selectedRecruitmentCampaign?.createdByUserId === item?.value
          ) {
            this.userValue = item;
          }
        });
      },
    },
    jobTypes: {
      deep: true,
      handler() {
        this.jobTypes.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.jobType?.value
              ? this.formData?.controls?.jobType?.value === item?.value
              : this.selectedRecruitmentCampaign?.recruitmentCampaignTypeId === item?.value
          ) {
            this.typeValue = item;
          }
        });
      },
    },
    user: {
      deep: true,
      handler() {
        if (this.permissions?.superAdminAccess) {
          this.formData.controls.company.validators = [Validators.required];
          this.formData.controls.user.validators = [Validators.required];
        }
      },
    },
    uploadedPrimaryImages: {
      deep: true,
      handler() {
        this.primaryImagesByAspectRatio();
      },
    },
    uploadedImages: {
      deep: true,
      handler() {
        let ui: any = this.uploadedImages;
        if (ui?.data?.data) {
          this.uploadedImages = ui?.data?.data;
        }
      },
    },
    brandTagOptions: {
      deep: true,
      handler() {
        this.recruitmentCampaignTagValues = [];
        this.brandTagOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.tags?.map((tag: ITag) => tag.tagId).includes(item.value)) {
            this.recruitmentCampaignTagValues.push(item);
          }
        });
      },
    },
    channels: {
      deep: true,
      handler() {
        if (this.selectedRecruitmentCampaign) {
          const locations = this.selectedRecruitmentCampaign?.stores;
          this.extractDataFromLocations(locations);
          this.campaignChannelValues = this.channelOptions.filter((channel: IDropdownOption) =>
            this.selectedRecruitmentCampaign?.channels?.includes(channel.value),
          );
        }
      },
    },
  },

  async beforeUnmount(): Promise<void> {
    if (SocketUtility.getSocket()) {
      SocketUtility.getSocket().off(SocketEvent.PRIMARY_IMAGE_PROGRESS_UPDATE);
      SocketUtility.getSocket().off(SocketEvent.SECONDARY_IMAGE_PROGRESS_UPDATE);
    }

    const controller = AbortHandler.getInstance();
    controller.voidControllers();

    this.fetchRecruitmentCampaigns();
  },

  async mounted(): Promise<void> {
    if (!SocketUtility.getSocket()) {
      SocketUtility.setSocket(new SocketService().imageUploadSocket);
    }

    SocketUtility.getSocket().on(SocketEvent.PRIMARY_IMAGE_PROGRESS_UPDATE, (percentage: number) => (this.primaryUploadPercentage = percentage));

    if (this.user?.onboarding) {
      if (this.initialLoad) {
        this.$notify({
          title: 'SUBMIT PERMISSIONS DISABLED',
          text:
            'You are currently unable to submit communications as your account activation remains incomplete. To finalize your account activation, kindly contact our team at team@engagementagents.com.',
          type: 'warn',
          duration: 5000,
        });
      }
    }

    if (this.modalData?.campaignId) {
      // open the preview sidebar if data exists
      this.previewOpen = true;
      await this.fetchRecruitmentCampaign({ campaignId: this.modalData?.campaignId });
      this.status = 'Edit';
      this.clearStores();
      this.loadChannels();
      this.setForm();
      this.selectEnglishAsDefaultLanguage();
      this.loadCompanies();
      this.loadRecruitmentCampaignTypes();
      this.loaded = true;
    } else {
      this.status = 'New';
      this.clearStores();
      this.loadChannels();
      this.setForm();
      this.selectEnglishAsDefaultLanguage();
      this.loadCompanies();
      this.loadRecruitmentCampaignTypes();
      if (this.access?.recruitmentCampaignsStore) {
        this.formData.controls.locations.value = [];
        this.selectedLocationIds = [];
        if (this.user?.brands[0].brandId) {
          this.fetchStoresByCommunicationType({
            communicationType: CommunicationTypes['Recruitment Campaign'],
            brandId: this.user?.brands[0].brandId,
          });
        }
      }
      this.loaded = true;
    }
  },
  data(): {
    status: string;
    previewOpen: boolean;
    changesMade: boolean;
    previewable: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    saving: boolean;
    duplicating: boolean;
    companyValue: IDropdownOption | null;
    userValue: IDropdownOption | null;
    brandValue: IDropdownOption | null;
    typeValue: IDropdownOption | null;
    customSearchValue: IDropdownOption;
    customSearchOptions: IDropdownOption[];
    searchLocationsFilter: string;
    tagValues: IDropdownOption[] | null;
    tagOptions: IDropdownOption[];
    searchFilter: string;
    locationOptions: IStore[];
    selectedLocationIds: string[];
    primaryImages: any[];
    secondaryImages: any[];
    languages: ILanguage[];
    activeLanguage: ILanguage;
    requiredImageSizes: IMallImageRequirement[];
    uploadedImages: IUploadedImage[];
    primaryUniqueRatioImages: IUploadedImage[];
    secondaryUniqueRatioImages: IUploadedImage[];
    formData: any;
    loaded: boolean;
    socialContacts: boolean;
    sameDescription: boolean;
    startASAP: boolean;
    publishDateUpdatedCount: number;
    startDateUpdatedCount: number;
    endDateUpdatedCount: number;
    primaryImagesUploading: boolean;
    secondaryImagesUploading: boolean;
    isNew: boolean;
    attemptedSubmit: boolean;
    showSameDescription: boolean;
    campaign: any;
    canCheckDates: boolean;
    fromCalendar: boolean;
    hasPrimaryImageChange: boolean;
    primaryUploadPercentage: number;
    backgroundBlurry: boolean;
    initialLoad: boolean;
    timeout: any;
    oversizedImage: boolean;
    allowShowOptions: boolean;
    timezones: any;
    timezone: IDropdownOption | null;
    selectedTimezone: string;
    savedTimezone: IDropdownOption | null;
    selectedReason: string;
    reasonMessage: string;
    reasonDescription: string;
    requirementsOpen: boolean;
    imageResizeRequired: boolean;
    tagComboTypes: IDropdownOption[] | null;
    tagComboType: IDropdownOption;
    languageSpecificImagesRequired: boolean;
    recruitmentCampaignTagValues: IDropdownOption[];
    channelOptions: IDropdownOption[];
    campaignChannelValues: IDropdownOption[];
    channelsTouched: boolean;
  } {
    return {
      status: 'New',
      previewOpen: false,
      changesMade: false,
      previewable: false,
      formValid: false,
      promptOpen: false,
      promptType: 'save',
      saving: false,
      duplicating: false,
      formData: null,
      loaded: false,
      socialContacts: true,
      sameDescription: true,
      startASAP: false,
      publishDateUpdatedCount: 0,
      startDateUpdatedCount: 0,
      endDateUpdatedCount: 0,
      languages: [
        {
          languageId: 37,
          description: 'English',
          isoCode: 'en',
          updatedAt: '',
          createdAt: '',
          required: true,
        },
      ],
      activeLanguage: {
        languageId: 37,
        description: 'English',
        isoCode: 'en',
        updatedAt: '',
        createdAt: '',
        required: true,
      },
      // temp metadata
      companyValue: null,
      userValue: null,
      brandValue: null,
      typeValue: null,
      customSearchValue: { description: 'All', value: '1' },
      customSearchOptions: [
        { description: 'All', value: '1' },
        { description: 'Canada', value: '2' },
        { description: 'US', value: '3' },
        { description: 'Canada & US', value: '4' },
      ],
      searchLocationsFilter: '',
      tagValues: [],
      tagOptions: [],
      searchFilter: '',
      locationOptions: [],
      selectedLocationIds: [],
      primaryImages: [],
      secondaryImages: [],
      requiredImageSizes: [],
      uploadedImages: [],
      primaryImagesUploading: false,
      secondaryImagesUploading: false,
      primaryUniqueRatioImages: [],
      secondaryUniqueRatioImages: [],
      isNew: true,
      attemptedSubmit: false,
      showSameDescription: true,
      campaign: null,
      canCheckDates: false,
      fromCalendar: false,
      hasPrimaryImageChange: false,
      primaryUploadPercentage: 0,
      backgroundBlurry: true,
      initialLoad: true,
      timeout: null,
      oversizedImage: false,
      allowShowOptions: false,
      timezones: IANA_TIMEZONES,
      timezone: null,
      selectedTimezone: dayjs.tz.guess(),
      savedTimezone: null,
      selectedReason: '',
      reasonMessage: '',
      reasonDescription: '',
      requirementsOpen: false,
      imageResizeRequired: false,
      tagComboTypes: [
        {
          value: 'or',
          description: 'OR',
        },
        {
          value: 'and',
          description: 'AND',
        },
      ],
      tagComboType: {
        value: 'or',
        description: 'OR',
      },
      languageSpecificImagesRequired: true,
      recruitmentCampaignTagValues: [],
      channelOptions: [],
      campaignChannelValues: [],
      channelsTouched: false,
    };
  },

  //
  // ███╗   ███╗███████╗████████╗██╗  ██╗ ██████╗ ██████╗ ███████╗
  // ████╗ ████║██╔════╝╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██╔════╝
  // ██╔████╔██║█████╗     ██║   ███████║██║   ██║██║  ██║███████╗
  // ██║╚██╔╝██║██╔══╝     ██║   ██╔══██║██║   ██║██║  ██║╚════██║
  // ██║ ╚═╝ ██║███████╗   ██║   ██║  ██║╚██████╔╝██████╔╝███████║
  // ╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝
  //
  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'clearStores',
      'loadCompanies',
      'fetchRecruitmentCampaign',
      'refreshCalendar',
      'loadRecruitmentCampaignTypes',
      'loadBrands',
      'loadUsers',
      'fetchStoresByCommunicationType',
      'loadBrandTags',
      'clearBrandTags',
      'loadStoreTags',
      'clearStoreTags',
      'saveRecruitmentCampaign',
      'updateRecruitmentCampaign',
      'updateModalData',
      'openModal',
      'updateModalType',
      'updateModalComponent',
      'showUpdateDrafts',
      'hideUpdateDrafts',
      'fetchBrandById',
      'fetchRecruitmentCampaigns',
      'loadChannels',
    ]),

    //
    // ███████╗███████╗████████╗    ███████╗ ██████╗ ██████╗ ███╗   ███╗
    // ██╔════╝██╔════╝╚══██╔══╝    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
    // ███████╗█████╗     ██║       █████╗  ██║   ██║██████╔╝██╔████╔██║
    // ╚════██║██╔══╝     ██║       ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
    // ███████║███████╗   ██║       ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
    // ╚══════╝╚══════╝   ╚═╝       ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
    //
    setForm(): void {
      // initializers
      let notes: any = [];
      let headline: any = [];
      let description: any = [];
      let twitterDesc: any = [];
      let instagramDesc: any = [];
      let facebookDesc: any = [];
      let images: any = [];
      let notesParsed: any;
      let headlineParsed: any;
      let descriptionParsed: any;
      let twitterDescParsed: any;
      let instagramDescParsed: any;
      let facebookDescParsed: any;
      let publishDate = '';
      let startDate = '';
      let endDate = '';
      let company = this.permissions?.superAdminAccess || this.isMallAdmin() ? '' : this.user?.companyId;
      let user = '';
      let brand = this.access?.recruitmentCampaignsStore ? this.user?.brands[0].brandId : '';
      let campaignName = '';
      let contactUrl = '';
      let jobType = '';
      let locations: any[] = [];
      let socialContacts = true;
      let channels = [];
      let sameDescription = true;
      let startASAP = false;
      let languageSpecificImagesRequired = true;
      this.timezone = this.timezones.find((timezone: any) => timezone.value === dayjs.tz.guess());

      // // set values if editing existing campaign
      if (this.selectedRecruitmentCampaign) {
        let campaign = this.selectedRecruitmentCampaign;
        this.campaign = campaign;

        this.uploadedImages = campaign?.images;

        //  parse multi language capable fields
        if (campaign?.notes) {
          notesParsed = this.languageParser(campaign?.notes);
        }
        if (campaign?.headline) {
          headlineParsed = this.languageParser(campaign?.headline);
        }
        if (campaign?.longDescription) {
          descriptionParsed = this.languageParser(campaign?.longDescription);
        }
        if (campaign?.twitterDescription) {
          twitterDescParsed = this.languageParser(campaign?.twitterDescription);
        }
        if (campaign?.instagramDescription) {
          instagramDescParsed = this.languageParser(campaign?.instagramDescription);
        }
        if (campaign?.facebookDescription) {
          facebookDescParsed = this.languageParser(campaign?.facebookDescription);
        }

        images = this.campaign?.images;
        company = campaign?.companyId ?? '';
        user = campaign?.createdByUserId ?? '';
        brand = campaign?.brandId ?? '';
        campaignName = campaign?.description ?? '';
        locations = campaign?.stores ?? [];
        jobType = campaign?.recruitmentCampaignTypeId ?? '';
        contactUrl = campaign?.contactUrl ?? '';

        socialContacts = campaign?.sendToSocialContacts;
        channels = campaign?.channels;
        sameDescription = campaign?.sameDescription;
        startASAP = campaign?.startASAP;
        languageSpecificImagesRequired = campaign?.languageSpecificImagesRequired ?? true;
        this.socialContacts = campaign?.sendToSocialContacts;
        this.sameDescription = campaign?.sameDescription;
        this.startASAP = campaign?.startASAP;
        this.backgroundBlurry = campaign?.backgroundBlurry ?? true;
        this.languageSpecificImagesRequired = languageSpecificImagesRequired;

        if (campaign?.publishByDate) {
          publishDate = campaign?.publishByDate;
        }
        if (campaign?.fromDate) {
          startDate = campaign?.fromDate;
        }
        if (campaign?.toDate) {
          endDate = campaign?.toDate;
        }

        if (this.campaign.statusId === 1) {
          if (
            dayjs(publishDate) <
            dayjs()
              .utcOffset(0)
              .startOf('day')
          ) {
            publishDate = '';
          }
          if (
            dayjs(startDate) <
            dayjs()
              .utcOffset(0)
              .startOf('day')
          ) {
            startDate = '';
          }
          if (
            dayjs(endDate) <
            dayjs()
              .utcOffset(0)
              .startOf('day')
          ) {
            endDate = '';
          }
        }

        // extract languages from locations
        this.extractDataFromLocations(locations);
        this.timezone = this.timezones.find((timezone: any) => timezone.value === this.selectedRecruitmentCampaign?.timezone);
        this.selectedTimezone = this.timezones.find((timezone: any) => timezone.value === this.selectedRecruitmentCampaign?.timezone)?.value;
        this.channelsTouched = true;

        // set dropdowns
        this.companyOptions?.forEach((item: IDropdownOption) => {
          if (company === item?.value) {
            this.companyValue = item;
          }
        });
        this.userOptions?.forEach((item: IDropdownOption) => {
          if (user === item?.value) {
            this.userValue = item;
          }
        });
        this.brandOptions?.forEach((item: IDropdownOption) => {
          if (brand === item?.value) {
            this.brandValue = item;
          }
        });
        this.jobTypes?.forEach((item: IDropdownOption) => {
          if (jobType === item?.value) {
            this.typeValue = item;
          }
        });
      }

      // // set multi language objects for new form
      this.languages?.forEach((language) => {
        let notesLang = '';
        let headlineLang = '';
        let descriptionLang = '';
        let twitterDescLang = '';
        let instagramDescLang = '';
        let facebookDescLang = '';
        if (this.selectedRecruitmentCampaign) {
          notesParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              notesLang = item[language?.isoCode];
            }
          });
          headlineParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              headlineLang = item[language?.isoCode];
            }
          });
          descriptionParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              descriptionLang = item[language?.isoCode];
            }
          });
          twitterDescParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              twitterDescLang = item[language?.isoCode];
            }
          });
          instagramDescParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              instagramDescLang = item[language?.isoCode];
            }
          });
          facebookDescParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              facebookDescLang = item[language?.isoCode];
            }
          });
        }
        if (
          this.selectedRecruitmentCampaign?.notes.includes(':::') ||
          this.selectedRecruitmentCampaign?.notes.includes('en=') ||
          this.selectedRecruitmentCampaign?.notes.includes('fr=')
        ) {
          notes.push({ language: language?.isoCode, value: notesLang ?? '' });
        } else {
          notes.push({ language: language?.isoCode, value: this.selectedRecruitmentCampaign?.notes ?? '' });
        }
        headline.push({ language: language?.isoCode, value: headlineLang });
        description.push({
          language: language?.isoCode,
          value: descriptionLang,
        });
        twitterDesc.push({
          language: language?.isoCode,
          value: twitterDescLang,
        });
        instagramDesc.push({
          language: language?.isoCode,
          value: instagramDescLang,
        });
        facebookDesc.push({
          language: language?.isoCode,
          value: facebookDescLang,
        });
        // images.push({ language: language?.isoCode, value: [], });
      });

      // set form based on user type
      this.formData = new FormBuilder({
        company: new FormControl(company),
        user: new FormControl(user),
        brand: new FormControl(brand, [Validators.required]),
        campaignName: new FormControl(campaignName, [Validators.required]),
        jobType: new FormControl(jobType, [Validators.required]),
        locations: new FormControl(locations, [Validators.required]),
        publishDate: new FormControl(publishDate, [Validators.required]),
        startDate: new FormControl(startDate),
        endDate: new FormControl(endDate, [Validators.required]),
        notes: new FormControl(notes),
        socialContacts: new FormControl(socialContacts ?? false),
        channels: new FormControl(channels, [Validators.required]),
        sameDescription: new FormControl(sameDescription ?? false),
        startASAP: new FormControl(startASAP ?? false),
        headline: new FormControl(headline, [Validators.required]),
        description: new FormControl(description, [Validators.required]),
        contactUrl: new FormControl(contactUrl, [Validators.required]),
        twitterDesc: new FormControl(twitterDesc),
        instagramDesc: new FormControl(instagramDesc),
        facebookDesc: new FormControl(facebookDesc),
        images: new FormControl(images),
        timezone: new FormControl(dayjs.tz.guess()),
        languageSpecificImagesRequired: new FormControl(languageSpecificImagesRequired),
      });

      this.languages?.forEach((language: any) => {
        let languageExists = false;
        this.formData?.controls?.headline?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.headline?.value?.push({ language: language?.isoCode, value: '' });
        }

        languageExists = false;
        this.formData?.controls?.notes?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.notes?.value?.push({ language: language?.isoCode, value: '' });
        }

        languageExists = false;
        this.formData?.controls?.description?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.description?.value?.push({ language: language?.isoCode, value: '' });
        }

        languageExists = false;
        this.formData?.controls?.facebookDesc?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.facebookDesc?.value?.push({ language: language?.isoCode, value: '' });
        }

        languageExists = false;
        this.formData?.controls?.twitterDesc?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.twitterDesc?.value?.push({ language: language?.isoCode, value: '' });
        }

        languageExists = false;
        this.formData?.controls?.instagramDesc?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.instagramDesc?.value?.push({ language: language?.isoCode, value: '' });
        }
      });
      setTimeout(() => {
        this.formData.controls.publishDate.value = publishDate;
        this.formData.controls.startDate.value = startDate;
        this.formData.controls.endDate.value = endDate;
        if (this.permissions?.superAdminAccess) {
          this.formData.controls.company.validators = [Validators.required];
          this.formData.controls.user.validators = [Validators.required];
        }
        if (this.isMallAdmin()) {
          this.formData.controls.company.validators = [Validators.required];
        }
        if (this.formData.controls.socialContacts.value) {
          this.formData.controls.twitterDesc.validators = [Validators.required];
          this.formData.controls.instagramDesc.validators = [Validators.required];
          this.formData.controls.facebookDesc.validators = [Validators.required];
        } else {
          this.formData.controls.twitterDesc.validators = [];
          this.formData.controls.instagramDesc.validators = [];
          this.formData.controls.facebookDesc.validators = [];
        }
        if (!this.formData.controls.startASAP.value) {
          this.formData.controls.startDate.validators = [Validators.required];
        } else {
          this.formData.controls.startDate.validators = [];
        }
        if (this.modalData?.endStr) {
          this.updateModalData(null);
        }
        if (this.formData.controls.brand.value) {
          this.fetchStoresByCommunicationType({
            communicationType: CommunicationTypes['Recruitment Campaign'],
            brandId: this.formData.controls.brand.value,
          });
        }
      }, 0);
      setTimeout(() => {
        this.canCheckDates = true;
        if (this.brandOptions && this.brandOptions?.length === 1 && brand === '') {
          this.updateBrand(this.brandOptions[0]);
        }
      }, 1000);

      setTimeout(() => {
        this.initialLoad = false;
      }, 3000);
    },
    gcd(a: number, b: number): number {
      return b === 0 ? a : this.gcd(b, a % b);
    },
    getAspectRatio(a: number, b: number): string {
      let ratio = '';
      let gcd = this.gcd(a, b);
      ratio = `${a / gcd}:${b / gcd}`;
      return ratio;
    },
    parseAspectRatio(a: string): { width: number; height: number } {
      let aspectRatio: any = {};
      const parse = a.split(':');
      aspectRatio.width = +parse[0];
      aspectRatio.height = +parse[1];
      return aspectRatio;
    },
    primaryImagesByAspectRatio() {
      if (this.uploadedPrimaryImages && this.uploadedPrimaryImages?.length > 0) {
        const uploadedImages = [...this.uploadedPrimaryImages];
        this.primaryUniqueRatioImages = uploadedImages.filter((image: any) => image?.generated === 1);
      }
    },
    loadingHandler({ type, overRide, overRideValue }: { type: 'primary' | 'secondary'; overRide: boolean; overRideValue: boolean }): boolean {
      if (type === 'primary') {
        if (overRide) {
          return (this.primaryImagesUploading = overRideValue);
        }

        return (this.primaryImagesUploading = !this.primaryImagesUploading);
      }

      if (overRide) {
        return (this.secondaryImagesUploading = overRideValue);
      }

      return (this.secondaryImagesUploading = !this.secondaryImagesUploading);
    },
    async readFile(file: Blob): Promise<Jimp> {
      return new Promise((resolve) => {
        const fileReader: FileReader = new FileReader();

        fileReader.onloadend = async (event): Promise<any> => {
          const image: Jimp = await jimp.read(event?.target?.result as string);

          return resolve(image);
        };

        fileReader.readAsDataURL(file);
      });
    },
    resizeImage({ image, requiredImageSize }: { image: Jimp; requiredImageSize: any }): Jimp {
      const { height: requiredHeight, width: requiredWidth } = requiredImageSize;
      let imageIsBound = false;

      while (!imageIsBound) {
        let { height: imageHeight, width: imageWidth } = image.bitmap;

        const imageBoundries = {
          heightOutOfBound: imageHeight > requiredHeight,
          heightInBound: imageHeight <= requiredHeight,
          widthOutOfBound: imageWidth > requiredWidth,
          widthInBound: imageWidth <= requiredWidth,
        };

        if (imageBoundries.heightInBound && imageBoundries.widthInBound) {
          const distanceForWidth = requiredWidth - imageWidth;
          const distanceForHeight = requiredHeight - imageHeight;

          if (distanceForWidth > distanceForHeight) {
            image.resize(jimp.AUTO, requiredHeight);
          } else {
            image.resize(requiredWidth, jimp.AUTO);
          }

          imageIsBound = true;
        }

        if (imageBoundries.heightOutOfBound) {
          image.resize(jimp.AUTO, requiredHeight);
        }

        if (imageBoundries.widthOutOfBound) {
          image.resize(requiredWidth, jimp.AUTO);
        }
      }

      return image;
    },
    getOffsets({ image, backgroundImage }: { image: any; backgroundImage: any }): { xOffset: number; yOffset: number } {
      const { width: imageWidth, height: imageHeight } = image.bitmap;
      const { width: backgroundWidth, height: backgroundHeight } = backgroundImage.bitmap;

      const offsets = {
        xOffset: 0,
        yOffset: 0,
      };

      if (imageHeight === backgroundHeight) {
        offsets.xOffset = (backgroundWidth - imageWidth) / 2;
      } else {
        offsets.yOffset = (backgroundHeight - imageHeight) / 2;
      }

      return {
        xOffset: offsets.xOffset,
        yOffset: offsets.yOffset,
      };
    },
    getImageOrientation(height: number, width: number): 'landscape' | 'portrait' | 'square' {
      if (height === width) return 'square';
      if (height > width) return 'portrait';

      return 'landscape';
    },
    isMasterImage(height: number, width: number, type: 'primary' | 'secondary'): boolean {
      const orientation = this.getImageOrientation(height, width);

      if (orientation === 'square') {
        const masterSquareImage: IUploadedImage | null = this.uploadedImages
          .filter(
            (el: IUploadedImage) => el.isMaster && el.type === type && el.orientation === 'square' && el.language === this.activeLanguage?.isoCode,
          )
          .sort((a: IUploadedImage, b: IUploadedImage) => b.height * b.width - a.height * a.width)[0];
        const isPerfectImageSize = this.requiredImageSizes.some((el: IMallImageRequirement) => el.height === height && el.width === width);

        if (!masterSquareImage) {
          return true;
        }

        if (isPerfectImageSize) {
          return false;
        }

        if (height * width > masterSquareImage.height * masterSquareImage.width) {
          return true;
        }
      }

      if (orientation === 'portrait') {
        const masterPortraitImage: IUploadedImage | null = this.uploadedImages
          .filter(
            (el: IUploadedImage) => el.isMaster && el.type === type && el.orientation === 'portrait' && el.language === this.activeLanguage?.isoCode,
          )
          .sort((a: IUploadedImage, b: IUploadedImage) => b.height * b.width - a.height * a.width)[0];
        const isPerfectImageSize = this.requiredImageSizes.some((el: IMallImageRequirement) => el.height === height && el.width === width);

        if (!masterPortraitImage) {
          return true;
        }

        if (isPerfectImageSize) {
          return false;
        }

        if (height * width > masterPortraitImage.height * masterPortraitImage.width) {
          return true;
        }
      }

      if (orientation === 'landscape') {
        const masterLandscapeImage: IUploadedImage | null = this.uploadedImages
          .filter(
            (el: IUploadedImage) => el.isMaster && el.type === type && el.orientation === 'landscape' && el.language === this.activeLanguage?.isoCode,
          )
          .sort((a: IUploadedImage, b: IUploadedImage) => b.height * b.width - a.height * a.width)[0];
        const isPerfectImageSize = this.requiredImageSizes.some((el: IMallImageRequirement) => el.height === height && el.width === width);

        if (!masterLandscapeImage) {
          return true;
        }

        if (isPerfectImageSize) {
          return false;
        }

        if (height * width > masterLandscapeImage.height * masterLandscapeImage.width) {
          return true;
        }
      }

      return false;
    },
    async uploadMasterImage(event: { file: Blob; type: string }, mainType: 'primary' | 'secondary'): Promise<void> {
      this.setChangesMade();

      if (mainType === 'primary') {
        this.hasPrimaryImageChange = true;
      }

      const image: Jimp = await this.readFile(event.file);
      const { height, width } = image.bitmap;

      const imageOrientation = this.getImageOrientation(height, width);
      const parsedRequirement = this.imageSizeRequirements[imageOrientation].split('x');

      if (parsedRequirement[0] !== 'Not Required') {
        const parsedRequirementWidth = parsedRequirement[0].split('w')[0];
        const parsedRequirementHeight = parsedRequirement[1].split('h')[0];
        const requiredOrientation = this.getImageOrientation(+parsedRequirementHeight, +parsedRequirementWidth);

        if (requiredOrientation !== imageOrientation || event.type !== imageOrientation) {
          this.loadingHandler({ type: mainType, overRide: true, overRideValue: false });
          return this.$notify({
            title: 'INVALID SIZE',
            text: 'Ensure the orientation of your image matches what you would like to upload.',
            type: 'error',
            duration: 5000,
          });
        }
      }

      const formData: FormData = new FormData();

      formData.append('images', event.file);

      formData.append('requirements', JSON.stringify(this.requiredImageSizes));
      formData.append('languageId', `${this.activeLanguage?.languageId}`);
      formData.append('processedImages', JSON.stringify(this.uploadedImages));
      formData.append('isMaster', 'true');

      if (!SocketUtility.getSocket().id) {
        const socketService = new SocketService();
        SocketUtility.setSocket(socketService.imageUploadSocket);
      }

      formData.append('socketId', SocketUtility.getSocket().id);

      this.loadingHandler({ type: mainType, overRide: true, overRideValue: true });

      const images = await new HttpService().generateRecruitmentImages(formData);

      this.loadingHandler({ type: mainType, overRide: true, overRideValue: false });
      this.uploadedImages = images.data.data;
      this.formData.controls.images.value = this.uploadedImages;
      this.imageResizeRequired = false;
    },
    async uploadSingleFile(image: Blob, type: 'primary' | 'secondary'): Promise<void> {
      if (type === 'primary') {
        this.hasPrimaryImageChange = true;
      }

      this.setChangesMade();
      const formData: FormData = new FormData();

      formData.append('images', image);

      formData.append('requirements', JSON.stringify(this.requiredImageSizes));
      formData.append('imageTypeId', '1');
      formData.append('languageId', `${this.activeLanguage?.languageId}`);
      formData.append('processedImages', JSON.stringify(this.uploadedImages));
      formData.append('isExactSize', 'true');

      if (!SocketUtility.getSocket().id) {
        const socketService = new SocketService();
        SocketUtility.setSocket(socketService.imageUploadSocket);
      }

      formData.append('socketId', SocketUtility.getSocket().id);

      this.loadingHandler({ type, overRide: true, overRideValue: true });

      const images = await new HttpService().generateRecruitmentImages(formData);

      this.uploadedImages = images.data.data;
      this.formData.controls.images.value = this.uploadedImages;

      this.loadingHandler({ type, overRide: true, overRideValue: false });
      this.imageResizeRequired = false;
    },
    async checkForOversized(image: any): Promise<any> {
      return new Promise((resolve, reject) => {
        try {
          let reader = new FileReader();
          const masterSquareImage = this.requiredImageSizes
            .filter((el: IMallImageRequirement) => el.height === el.width)
            .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
          const masterPortraitImage = this.requiredImageSizes
            .filter((el: IMallImageRequirement) => el.height > el.width)
            .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
          const masterLandscapeImage = this.requiredImageSizes
            .filter((el: IMallImageRequirement) => el.height < el.width)
            .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.width - a.width)[0];

          //Read the contents of Image File.
          reader.readAsDataURL(image);
          reader.onloadend = () => {
            //Initiate the JavaScript Image object.
            let i: any = new Image();

            //Set the Base64 string return from FileReader as source.
            i.src = reader?.result;

            const trickery = (height: number, width: number) => {
              if (height > width) {
                if (height > masterPortraitImage?.height && width > masterPortraitImage?.width) {
                  this.oversizedImage = true;
                }
              }
              if (height < width) {
                if (height > masterLandscapeImage?.height && width > masterLandscapeImage?.width) {
                  this.oversizedImage = true;
                }
              }
              if (height === width) {
                if (height > masterSquareImage?.height && width > masterSquareImage?.width) {
                  this.oversizedImage = true;
                }
              }
              resolve(true);
            };

            //Validate the File Height and Width.
            i.onload = function() {
              trickery(this?.height, this?.width);
            };
          };
        } catch (error) {
          reject(error);
        }
      });
    },
    async primaryFileChange(images: FileList): Promise<void> {
      this.hasPrimaryImageChange = true;
      this.setChangesMade();

      if (this.languageSpecificImagesRequired) {
        await this.generateImagesHelper(images, [this.activeLanguage?.languageId]);
      } else {
        await this.generateImagesHelper(
          images,
          this.languages.map((el: ILanguage) => el.languageId),
        );
      }
    },
    async generateImagesHelper(images: any, languageIds: number[], imageTypeId?: string): Promise<void> {
      const formData: FormData = new FormData();

      // let singleAbove10mb = false;
      // for (const image of images) {
      //   await this.checkForOversized(image);
      //   if (image?.size >= 10000000) {
      //     singleAbove10mb = true;
      //   }
      // }
      //
      // if (singleAbove10mb) {
      //   this.$notify({
      //     title: 'LARGE FILE SIZE DETECTED',
      //     text: 'We recommend keeping any uploaded image below 10mb, and for the best performance below 1mb!',
      //     type: 'warn',
      //     duration: 11000,
      //   });
      // }
      //
      // if (this.oversizedImage) {
      //   this.$notify({
      //     title: 'LARGE IMAGE SIZE DETECTED',
      //     text:
      //       'While you can continue to upload these images "as is" reducing the file size of these and future images will increase the responsiveness and load times. If you keep these images, please wait patiently as it could take several minutes to generate your required images. Please see our recommended image sizes for your campaign OR if you need assistance with resizing, please email your images to team@engagementagents.com and we will resize for you. Alternatively, if you have any questions, please click here to chat live OR contact your Account Manager. ',
      //     type: 'warn',
      //     duration: 11000,
      //   });
      // }

      for (let imageNumber = 0; imageNumber < images.length; imageNumber++) {
        formData.append('images', images[imageNumber]);
      }

      formData.append('requirements', JSON.stringify(this.requiredImageSizes));
      if (languageIds.length === 1) {
        formData.append('languageId', `${languageIds[0]}`);
        formData.append('processedImages', JSON.stringify(this.uploadedImages.filter((image: any) => image?.languageId === languageIds[0])));
      } else {
        formData.append('languageIds', JSON.stringify(languageIds));
        formData.append('processedImages', JSON.stringify(this.uploadedImages));
      }
      formData.append('backgroundBlurry', `${this.backgroundBlurry}`);

      if (!SocketUtility.getSocket().id) {
        const socketService = new SocketService();
        SocketUtility.setSocket(socketService.imageUploadSocket);
      }

      formData.append('socketId', SocketUtility.getSocket().id);

      this.loadingHandler({ type: 'primary', overRide: true, overRideValue: true });
      try {
        const images = await new HttpService().generateRecruitmentImages(formData);
        if (languageIds.length === 1) {
          this.uploadedImages = [
            ...this.uploadedImages.filter((image: any) => image?.languageId !== languageIds[0]),
            ...images.data.data.filter((image: any) => image?.languageId === languageIds[0]),
          ];
        } else {
          this.uploadedImages = [...images.data.data];
        }
        this.formData.controls.images.value = this.uploadedImages;
      } catch (err) {
        if (this.oversizedImage) {
          this.$notify({
            title: 'ERROR: IMAGE SIZES TOO LARGE',
            text:
              'The uploaded images are too large, and the upload/generation process has failed. Please see our recommended image sizes for your campaign, resize them and try again OR if you need assistance with resizing, please email your images to team@engagementagents.com and we will resize for you.',
            type: 'error',
            duration: 11000,
          });
        } else {
          this.$notify({
            title: 'ERROR',
            text:
              'An unknown error has occurred and your images failed to upload & generate. Please check your file sizes and try again, or contact us at team@engagementagents.com for support.',
            type: 'error',
            duration: 11000,
          });
        }
      }
      this.loadingHandler({ type: 'primary', overRide: true, overRideValue: false });
      this.oversizedImage = false;
      this.imageResizeRequired = false;
    },
    async deleteImage(image: any) {
      const languageId = this.activeLanguage?.languageId;
      this.setChangesMade();
      const formData: FormData = new FormData();
      formData.append('requirements', JSON.stringify(this.requiredImageSizes));
      formData.append(
        'processedImages',
        JSON.stringify(
          this.uploadedImages?.filter(
            (img: any) => img?.generated === 0 && img?.url !== image?.url && img?.languageId === this.activeLanguage.languageId,
          ),
        ),
      );
      formData.append('backgroundBlurry', `${this.backgroundBlurry}`);
      formData.append('imageTypeId', '1');
      formData.append('imageToDelete', `${image?.url}`);
      formData.append('languageId', `${languageId}`);

      if (!SocketUtility.getSocket().id) {
        const socketService = new SocketService();
        SocketUtility.setSocket(socketService.imageUploadSocket);
      }

      formData.append('socketId', SocketUtility.getSocket().id);

      this.loadingHandler({ type: 'primary', overRide: true, overRideValue: true });
      const images: any = await new HttpService().generateRecruitmentImages(formData);
      this.uploadedImages = [
        ...this.uploadedImages.filter((image: any) => image?.languageId !== this.activeLanguage?.languageId),
        ...images.data.data.filter((image: any) => image?.languageId === this.activeLanguage.languageId),
      ];
      this.formData.controls.images.value = this.uploadedImages;
      this.loadingHandler({ type: 'primary', overRide: true, overRideValue: false });
      this.imageResizeRequired = false;
    },
    languageParser(value: string) {
      const parsedData: any = [];
      const languages = value?.split('::: ');
      languages.forEach((language) => {
        parsedData.push({
          [language.substring(0, language.indexOf('='))]: language.substring(language.indexOf('=') + 1, language.length),
        });
      });
      return parsedData;
    },
    languageSpecificImages(language: ILanguage) {
      let primary;
      let secondary;
      if (this.uploadedImages && this.uploadedImages.length > 0 && this.uploadedImages[0].languageId) {
        primary = this.primaryUniqueRatioImages.filter((image: any) => image.languageId === language.languageId);
        secondary = this.secondaryUniqueRatioImages.filter((image: any) => image.languageId === language.languageId);
      } else {
        primary = this.primaryUniqueRatioImages.filter((image: any) => image.language === language.isoCode);
        secondary = this.secondaryUniqueRatioImages.filter((image: any) => image.language === language.isoCode);
      }
      const images = [...primary, ...secondary];
      return images;
    },
    getLanguageSpecificValue(item: any, isoCode: string) {
      let value: any = '';
      item?.forEach((i: any) => {
        if (i?.language === isoCode) {
          // if (i?.language === this.activeLanguage?.isoCode) {
          value = i?.value;
        }
      });
      return value;
    },
    setLanguageSpecificValue(item: any, value: any, isoCode: string) {
      item?.forEach((i: any) => {
        if (i?.language === isoCode) {
          // if (i?.language === this.activeLanguage?.isoCode) {
          i.value = value;
        }
      });
    },
    languageSelected(value: ILanguage, fromForm = false) {
      this.activeLanguage = value;
      if (fromForm) {
        this.setChangesMade();
      }
      if (this.attemptedSubmit) {
        this.validateControl('headline');
        this.validateControl('description');
        this.validateControl('twitterDesc');
        this.validateControl('instagramDesc');
        this.validateControl('facebookDesc');
        this.checkLanguageErrors();
      } else {
        this.formData.controls.headline.errors = [];
        this.formData.controls.description.errors = [];
        this.formData.controls.twitterDesc.errors = [];
        this.formData.controls.instagramDesc.errors = [];
        this.formData.controls.facebookDesc.errors = [];
      }
    },
    checkLanguageErrors() {
      this.languages?.forEach((language: ILanguage) => {
        let hasErrors = false;
        if (language.required) {
          this.formData.controls.headline.value.forEach((el: any) => {
            if (el.language === language.isoCode && el.value === '') {
              hasErrors = true;
            }
          });
          this.formData.controls.description.value.forEach((el: any) => {
            if (el.language === language.isoCode && el.value === '') {
              hasErrors = true;
            }
          });
          if (this.formData.controls.socialContacts.value === true) {
            this.formData.controls.twitterDesc.value.forEach((el: any) => {
              if (el.language === language.isoCode && el.value === '') {
                hasErrors = true;
              }
            });
            this.formData.controls.instagramDesc.value.forEach((el: any) => {
              if (el.language === language.isoCode && el.value === '') {
                hasErrors = true;
              }
            });
            this.formData.controls.facebookDesc.value.forEach((el: any) => {
              if (el.language === language.isoCode && el.value === '') {
                hasErrors = true;
              }
            });
          }
        }
        language.hasErrors = hasErrors;
      });
    },
    languageButtonStyle(language: ILanguage) {
      let style = '';
      if (language === this.activeLanguage) {
        style = language?.hasErrors
          ? 'color: hsl(0, 0%, 100%); background-color: hsl(138, 50%, 61%); border-color: red;'
          : 'color: hsl(0, 0%, 100%); background-color: hsl(138, 50%, 61%);';
      } else {
        style = language?.hasErrors ? 'color: hsl(0, 0%, 44%); border-color: red;' : 'color: hsl(0, 0%, 44%)';
      }
      return style;
    },
    daysBetween(d1: any, d2: any): number {
      let diff = Math.abs(d1.getTime() - d2.getTime());
      return diff / (1000 * 60 * 60 * 24);
    },
    isToday(date: any): boolean {
      const today = new Date();
      return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    },
    extractDataFromLocations(locations: IStore[]) {
      this.selectedLocationIds = [];
      if (locations?.length > 0) {
        const languageExtraction: any = [...new Set(locations.map((el: IStore) => el?.mall?.languages))];
        const mergedLanguages: ILanguage[] = [].concat.apply([], languageExtraction);

        const extractedRequirements: any = locations.map((el: IStore) => el?.mall?.requirements);
        const mergedRequirements: IMallImageRequirement[] = [].concat.apply([], extractedRequirements);

        const extractedChannels: any = locations.map((el: IStore) => el?.mall?.channels);
        const mergedChannels: any[] = [].concat.apply([], extractedChannels);
        const uniqueChannels = [...new Set(mergedChannels.map((el: any) => el?.channelId))];
        this.channelOptions = this.channels.filter((channel: IDropdownOption) => uniqueChannels.includes(channel?.value));

        let uniqueLanguages: any[] = [];
        const map = new Map();
        for (const item of mergedLanguages) {
          if (!map.has(item.isoCode)) {
            map.set(item.isoCode, true);
            item.required = item?.Mall_Language?.languageTypeId === 1 || item?.Mall_Language?.languageTypeId === 2;
            uniqueLanguages.push(item);
          } else {
            if (item?.Mall_Language?.languageTypeId === 1 || item?.Mall_Language?.languageTypeId === 2) {
              uniqueLanguages = uniqueLanguages.filter((el: any) => item?.isoCode !== el?.isoCode);
              uniqueLanguages.push({ ...item, required: true });
            }
          }
        }

        const requirements: any = mergedRequirements
          .map((el: IMallImageRequirement) => ({
            ...el,
            dimensions: `${el.width}x${el.height}`,
            aspectRatio: `${this.getAspectRatio(el.width, el.height)}`,
          }))
          .filter((thing, index, self) => index === self.findIndex((t) => t.dimensions === thing.dimensions));

        this.requiredImageSizes = requirements;

        this.languages = uniqueLanguages;
        this.languages?.forEach((language: ILanguage) => {
          this.activeLanguage = language;

          let languageExists = false;
          this.formData?.controls?.headline?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.headline?.value?.push({ language: language?.isoCode, value: '' });
          }

          languageExists = false;
          this.formData?.controls?.notes?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.notes?.value?.push({ language: language?.isoCode, value: '' });
          }

          languageExists = false;
          this.formData?.controls?.description?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.description?.value?.push({ language: language?.isoCode, value: '' });
          }

          languageExists = false;
          this.formData?.controls?.facebookDesc?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.facebookDesc?.value?.push({ language: language?.isoCode, value: '' });
          }

          languageExists = false;
          this.formData?.controls?.twitterDesc?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.twitterDesc?.value?.push({ language: language?.isoCode, value: '' });
          }

          languageExists = false;
          this.formData?.controls?.instagramDesc?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.instagramDesc?.value?.push({ language: language?.isoCode, value: '' });
          }
        });

        const englishIndex = this.languages.findIndex((item) => item.isoCode === 'en');
        if (englishIndex > -1) {
          this.activeLanguage = this.languages[englishIndex];
        }

        if (this.attemptedSubmit) {
          this.checkLanguageErrors();
        }

        locations.forEach((location: IStore) => {
          this.selectedLocationIds.push(location.storeId);
        });
      } else {
        this.languages = [
          {
            languageId: 37,
            description: 'English',
            isoCode: 'en',
            updatedAt: '',
            createdAt: '',
            required: true,
          },
        ];
        this.activeLanguage = {
          languageId: 37,
          description: 'English',
          isoCode: 'en',
          updatedAt: '',
          createdAt: '',
          required: true,
        };
      }
    },
    selectEnglishAsDefaultLanguage() {
      const englishIndex = this.languages.findIndex((item) => item.isoCode === 'en');
      if (englishIndex > -1) {
        this.languageSelected(this.languages[englishIndex]);
      } else {
        this.languageSelected(this.languages[0]);
      }
    },
    addAllChannels(): void {
      this.campaignChannelValues = this.channelOptions;
      this.formData.controls.channels.value = this.channelOptions;
    },
    removeChannelsNotInOptions(): void {
      this.campaignChannelValues = this.campaignChannelValues.filter((channel: IDropdownOption) =>
        this.channelOptions.some((option: IDropdownOption) => option.value === channel.value),
      );
      this.formData.controls.channels.value = this.campaignChannelValues;
    },
    locationAdded(value: any): void {
      this.formData.controls.locations.value.push(value);
      this.formData.controls.locations.value.sort((a: IStore, b: IStore) =>
        a.storeNumber.toString().localeCompare(b.storeNumber.toString(), undefined, { numeric: true }),
      );
      setTimeout(() => {
        if (!this.channelsTouched) {
          this.addAllChannels();
        }
      }, 1000);
      this.setChangesMade();
    },
    locationRemoved(value: any): void {
      const index = this.formData.controls.locations.value.findIndex((item: any) => item.storeId === value.storeId);
      this.formData.controls.locations.value.splice(index, 1);
      setTimeout(() => {
        this.removeChannelsNotInOptions();
        if (!this.channelsTouched) {
          this.addAllChannels();
        }
      }, 1000);
      this.setChangesMade();
    },
    addAllLocations(): void {
      this.filteredLocationsAvailable.forEach((location: any) => {
        this.locationAdded(location);
      });
    },
    removeAllLocations(): void {
      this.filteredLocationsSelected.forEach((location: any) => {
        this.locationRemoved(location);
      });
    },
    async updateCompany(value: IDropdownOption, clicked?: boolean) {
      this.companyValue = value;
      this.formData.controls.company.value = this.companyValue.value;
      await this.loadBrands({ companyId: value.value });
      await this.loadUsers({ companyId: value.value });
      if (clicked) {
        this.brandValue = null;
        this.formData.controls.brand.value = '';
        this.userValue = null;
        this.formData.controls.user.value = '';
        this.clearStores();
        this.clearBrandTags();
        this.clearStoreTags();
        this.setChangesMade();
        this.validateControl('company');
        setTimeout(() => {
          if (this.brandOptions && this.brandOptions?.length === 1) {
            this.updateBrand(this.brandOptions[0]);
          }
        }, 3000);
      }
    },
    clearCompany(value: any) {
      if (value === '') {
        this.companyValue = null;
        this.formData.controls.company.value = null;
        this.setChangesMade();
        this.validateControl('company');
      }
    },
    updateUser(value: IDropdownOption) {
      this.userValue = value;
      this.formData.controls.user.value = this.userValue.value;
      this.setChangesMade();
      this.validateControl('user');
    },
    async updateBrand(value: IDropdownOption, fromForm = false) {
      if (!value?.value) {
        return;
      }

      if (fromForm) {
        const brand = await this.fetchBrandById(value.value);
        this.backgroundBlurry = brand?.backgroundBlurry ?? true;
        this.setChangesMade('BRAND');
        this.setPreviewable();
        this.validateControl('brand');
        this.formData.controls.locations.value = [];
        this.selectedLocationIds = [];
      }
      this.fetchStoresByCommunicationType({
        communicationType: CommunicationTypes['Recruitment Campaign'],
        brandId: value?.value,
      });
      this.brandValue = value;
      this.formData.controls.brand.value = this.brandValue?.value;
      this.loadBrandTags({ brandId: this.formData.controls.brand.value });
      this.loadStoreTags({ brandIds: [this.formData.controls.brand.value] });
    },
    updateType(value: IDropdownOption) {
      this.typeValue = value;
      this.formData.controls.jobType.value = this.typeValue.value;
      this.setChangesMade();
      this.validateControl('jobType');
    },
    updateTimezone(value: IDropdownOption) {
      this.timezone = value;
      this.formData.controls.timezone.value = this.timezone.value;
      this.selectedTimezone = this.timezones.find((timezone: any) => timezone.value === value.value)?.value;
      this.formData.controls.startDate.value = null;
      this.formData.controls.endDate.value = null;
      this.formData.controls.publishDate.value = null;
    },
    clearTimezone() {
      if (this.timezone) {
        this.savedTimezone = this.timezone;
        this.timezone = null;
      }
    },
    fixTimezone() {
      setTimeout(() => {
        if (!this.timezone) {
          this.timezone = this.savedTimezone;
        }
      }, 100);
    },
    tagsUpdated(value: any): void {
      this.tagValues = value;
    },
    recruitmentCampaignTagsUpdated(value: any): void {
      this.recruitmentCampaignTagValues = value;
      this.setChangesMade();
    },
    updateTagComboType(value: IDropdownOption) {
      this.tagComboType = value;
      this.setChangesMade();
      this.validateControl('campaignType');
    },
    setChangesMade(type?: string) {
      this.changesMade = true;
    },
    setPreviewable() {
      this.previewable = true;
      if (this.status === 'New') {
        this.previewOpen = true;
      }
    },
    updateNotes(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.notes.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade();
      this.setPreviewable();
    },
    updateHeadline(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.headline.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade();
      this.setPreviewable();
      this.validateControl('headline');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    updateDescription(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.description.value, value, this.activeLanguage?.isoCode);
      if (this.formData?.controls?.socialContacts?.value && this.formData?.controls?.sameDescription?.value) {
        this.updateTwitterDesc(value);
        this.updateInstagramDesc(value);
        this.updateFacebookDesc(value);
      }
      this.setChangesMade();
      this.setPreviewable();
      this.validateControl('description');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    updateTwitterDesc(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.twitterDesc.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade();
      this.setPreviewable();
      this.validateControl('twitterDesc');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    updateInstagramDesc(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.instagramDesc.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade();
      this.setPreviewable();
      this.validateControl('instagramDesc');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    updateFacebookDesc(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.facebookDesc.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade();
      this.setPreviewable();
      this.validateControl('facebookDesc');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    toggleSocialContacts() {
      this.formData.controls.socialContacts.value = !this.formData.controls.socialContacts.value;
      if (!this.formData.controls.socialContacts.value) {
        this.formData.controls.sameDescription.value = false;
        this.sameDescription = false;
        this.showSameDescription = false;
        setTimeout(() => {
          this.showSameDescription = true;
        }, 0);
      }
      this.setChangesMade();
      if (this.formData.controls.socialContacts.value) {
        this.formData.controls.twitterDesc.validators = [Validators.required];
        this.formData.controls.instagramDesc.validators = [Validators.required];
        this.formData.controls.facebookDesc.validators = [Validators.required];
      } else {
        this.formData.controls.twitterDesc.validators = [];
        this.formData.controls.instagramDesc.validators = [];
        this.formData.controls.facebookDesc.validators = [];
      }
    },
    toggleSameDescription() {
      this.formData.controls.sameDescription.value = !this.formData.controls.sameDescription.value;
      if (this.formData.controls.sameDescription.value) {
        this.formData.controls.twitterDesc.value = this.formData?.controls?.description?.value;
        this.formData.controls.instagramDesc.value = this.formData?.controls?.description?.value;
        this.formData.controls.facebookDesc.value = this.formData?.controls?.description?.value;
      }
      this.setChangesMade();
    },
    toggleStartASAP() {
      this.formData.controls.startASAP.value = !this.formData.controls.startASAP.value;
      this.setChangesMade();
      if (this.formData.controls.startASAP.value) {
        this.formData.controls.startDate.validators = [];
      } else {
        this.formData.controls.startDate.validators = [Validators.required];
      }
    },
    toggleLanguageSpecificImagesRequired() {
      this.languageSpecificImagesRequired = !this.languageSpecificImagesRequired;
      this.formData.controls.languageSpecificImagesRequired.value = !this.formData.controls.languageSpecificImagesRequired.value;
      this.setChangesMade();
    },
    updateSearchLocationsFilter(value: string) {
      this.searchFilter = value;
    },
    validateDateControl(controlName: string): void {
      if (controlName === 'publishDate') {
        this.publishDateUpdatedCount++;
        if (!this.initialLoad && (this.publishDateUpdatedCount > 1 || this.status === 'New')) {
          this.setChangesMade();
          this.setPreviewable();
        }
      }
      if (controlName === 'startDate') {
        this.startDateUpdatedCount++;
        if (!this.initialLoad && (this.startDateUpdatedCount > 1 || this.status === 'New')) {
          this.setChangesMade();
          this.setPreviewable();
        }
      }
      if (controlName === 'endDate') {
        this.endDateUpdatedCount++;
        if (!this.initialLoad && (this.endDateUpdatedCount > 1 || this.status === 'New')) {
          this.setChangesMade();
          this.setPreviewable();
        }
      }
      setTimeout(() => {
        this.formData.controls[controlName].validate();
      }, 0);
    },
    validateControl(controlName: string): void {
      this.setChangesMade();
      if (controlName === 'headline') {
        if (this.activeLanguage.required) {
          this.formData.controls.headline.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.headline.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.headline.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.headline.errors = [];
        }
      } else if (controlName === 'description') {
        if (this.activeLanguage.required) {
          this.formData.controls.description.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.description.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.description.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.description.errors = [];
        }
      } else if (controlName === 'twitterDesc') {
        if (this.activeLanguage.required && this.formData.controls.socialContacts.value) {
          this.formData.controls.twitterDesc.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.twitterDesc.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.twitterDesc.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.twitterDesc.errors = [];
        }
      } else if (controlName === 'instagramDesc') {
        if (this.activeLanguage.required && this.formData.controls.socialContacts.value) {
          this.formData.controls.instagramDesc.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.instagramDesc.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.instagramDesc.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.instagramDesc.errors = [];
        }
      } else if (controlName === 'facebookDesc') {
        if (this.activeLanguage.required && this.formData.controls.socialContacts.value) {
          this.formData.controls.facebookDesc.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.facebookDesc.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.facebookDesc.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.facebookDesc.errors = [];
        }
      } else if (controlName === 'contactUrl') {
        if (this.formData.controls.contactUrl.value === '') {
          this.formData.controls.contactUrl.errors = {
            message: 'Field is required',
            status: 'error',
          };
        } else {
          if (!this.formData.controls.contactUrl.value?.includes('http') && !this.formData.controls.contactUrl.value?.includes('@')) {
            this.formData.controls.contactUrl.errors = {
              message: 'Has to be a valid url or email',
              status: 'error',
            };
          } else {
            this.formData.controls.contactUrl.errors = [];
          }
        }
      } else {
        setTimeout(() => {
          this.formData.controls[controlName].validate();
        }, 0);
      }
    },
    formatDate(date: string): string {
      return dateFormatter(date);
    },
    closeRequirements(): void {
      this.requirementsOpen = false;
    },
    openRequirements(): void {
      this.requirementsOpen = true;
    },
    copyRequirements(): void {
      let requirements = 'Location,Image Size Requirements\n';
      this.formData.controls.locations.value.forEach((store: any) => {
        requirements += `${store?.mall?.description}${store.storeIdentifier ? ` (${store.storeIdentifier})` : ''}`;
        if (store?.mall?.requirements?.length === 0) {
          requirements += 'No Requirements\n';
        } else {
          requirements += ',';
          store?.mall?.requirements?.forEach((requirement: any, index: any) => {
            requirements += requirement?.width + 'x' + requirement?.height + (index < store?.mall?.requirements?.length - 1 ? ',' : '\n');
          });
        }
      });
      const blob = new Blob([requirements], { type: 'text/csv;charset=utf-8;' });
      let link = document.createElement('a');
      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'image_requirements.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.$notify({
        title: 'SUCCESS',
        text: `Requirements CSV Downloaded!`,
        type: 'info',
        duration: 5000,
      });
    },
    closePrompt(): void {
      this.promptOpen = false;
      this.selectedReason = '';
      this.reasonDescription = '';
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
      this.selectedReason = '';
      this.reasonDescription = '';
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    async delete(): Promise<void> {
      const payload = this.campaign;
      payload.statusId = Status.Deleted;
      this.saving = true;
      try {
        await this.updateRecruitmentCampaign({ body: payload, campaignId: this.campaign?.recruitmentCampaignId });
        this.$notify({
          title: 'SUCCESS',
          text: `Recruitment Campaign deleted successfully!`,
          type: 'success',
          duration: 5000,
        });
        this.refreshCalendar();
        await this.fetchRecruitmentCampaigns();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: `Unable to delete Recruitment Campaign!`,
          type: 'error',
          duration: 5000,
        });
      } finally {
        this.saving = false;
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    expirePrompt(): void {
      this.openPrompt('expire');
    },
    async expire(): Promise<void> {
      const payload = this.campaign;
      payload.statusId = Status['Manually Expired'];
      payload.reasonForExpire = this.reasonDescription;
      payload.expiredByUserId = this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId ?? '';
      this.saving = true;
      try {
        await this.updateRecruitmentCampaign({ body: payload, campaignId: this.campaign?.recruitmentCampaignId });
        this.$notify({
          title: 'SUCCESS',
          text: `Recruitment Campaign expired successfully!`,
          type: 'success',
          duration: 5000,
        });
        this.refreshCalendar();
        await this.fetchRecruitmentCampaigns();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: `Unable to expire Recruitment Campaign!`,
          type: 'error',
          duration: 5000,
        });
      } finally {
        this.saving = false;
      }
    },
    async save(submit?: boolean): Promise<void> {
      if (this.formData?.controls?.brand?.value) {
        let upcoming = false;

        const publishByDate = this.formData?.controls?.publishDate?.value ?? '';

        if (this.formData?.controls?.publishDate?.value) {
          let pd = new Date(this.formData?.controls?.publishDate?.value);
          upcoming = new Date().setHours(23, 59, 59, 999) < pd.setHours(0, 0, 0, 0);
        }

        const fromDate = this.formData?.controls?.startDate?.value ?? '';

        const toDate = this.formData?.controls?.endDate?.value ?? '';
        let notes = '';
        if (this.formData?.controls?.notes?.value) {
          let items = [...this.formData?.controls?.notes?.value];
          items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
          items?.forEach((el: any) => {
            notes += `${el.language}=${el.value}::: `;
          });
          notes = notes.slice(0, notes.length - 4);
        }
        let headline = '';
        if (this.formData?.controls?.headline?.value) {
          let items = [...this.formData?.controls?.headline?.value];
          items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
          items?.forEach((el: any) => {
            headline += `${el.language}=${el.value}::: `;
          });
          headline = headline.slice(0, headline.length - 4);
        }
        let longDescription = '';
        if (this.formData?.controls?.description?.value) {
          let items = [...this.formData?.controls?.description?.value];
          items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
          items?.forEach((el: any) => {
            longDescription += `${el.language}=${el.value}::: `;
          });
          longDescription = longDescription.slice(0, longDescription.length - 4);
        }
        let facebookDesc = '';
        if (this.formData?.controls?.facebookDesc?.value) {
          let items = [...this.formData?.controls?.facebookDesc?.value];
          items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
          items?.forEach((el: any) => {
            facebookDesc += `${el.language}=${el.value}::: `;
          });
          facebookDesc = facebookDesc.slice(0, facebookDesc.length - 4);
        }
        let twitterDesc = '';
        if (this.formData?.controls?.twitterDesc?.value) {
          let items = [...this.formData?.controls?.twitterDesc?.value];
          items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
          items?.forEach((el: any) => {
            twitterDesc += `${el.language}=${el.value}::: `;
          });
          twitterDesc = twitterDesc.slice(0, twitterDesc.length - 4);
        }
        let instagramDesc = '';
        if (this.formData?.controls?.instagramDesc?.value) {
          let items = [...this.formData?.controls?.instagramDesc?.value];
          items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
          items?.forEach((el: any) => {
            instagramDesc += `${el.language}=${el.value}::: `;
          });
          instagramDesc = instagramDesc.slice(0, instagramDesc.length - 4);
        }
        const payload = {
          recruitmentCampaignTypeId: this.formData?.controls?.jobType?.value,
          description: this.formData?.controls?.campaignName?.value,
          facebookDescription: facebookDesc,
          twitterDescription: twitterDesc,
          instagramDescription: instagramDesc,
          notes: notes,
          companyId: this.formData?.controls?.company?.value,
          brandId: this.formData?.controls?.brand?.value,
          createdByUserId: this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId,
          headline: headline,
          longDescription: longDescription,
          publishByDate: publishByDate,
          fromDate: fromDate,
          toDate: toDate,
          contactUrl: this.formData?.controls?.contactUrl?.value,
          sameDescription: this.formData?.controls?.sameDescription?.value,
          // sendToSocialContacts: this.formData?.controls?.socialContacts?.value,
          statusId: submit === true ? (upcoming ? 3 : 2) : 1,
          storeIds: this.selectedLocationIds,
          images: this.formData?.controls?.images?.value,
          hasPrimaryImageChange: this.hasPrimaryImageChange,
          backgroundBlurry: this.backgroundBlurry,
          timezone: this.timezone?.value,
          languageSpecificImagesRequired: this.formData?.controls?.languageSpecificImagesRequired?.value ?? true,
          tagIds: this.recruitmentCampaignTagValues.map((tag) => tag.value),
          channels: this.campaignChannelValues.map((channel) => channel.value),
        };
        this.saving = true;
        try {
          if (submit === true) {
            this.formData.markAllAsTouched();
            this.validateControl('headline');
            this.validateControl('description');
            this.validateControl('twitterDesc');
            this.validateControl('instagramDesc');
            this.validateControl('facebookDesc');
            this.checkLanguageErrors();
            this.attemptedSubmit = true;
          }
          if (this.campaign?.recruitmentCampaignId) {
            await this.updateRecruitmentCampaign({ body: payload, campaignId: this.campaign?.recruitmentCampaignId });
          } else {
            this.campaign = await this.saveRecruitmentCampaign(payload);
            this.status = 'Edit';
          }
          this.refreshCalendar();
          this.$notify({
            title: 'SUCCESS',
            text: `Recruitment Campaign ${submit === true ? 'submitted' : 'saved'} successfully!`,
            type: 'success',
            duration: 5000,
          });
          if (this.fromCalendar && !(submit === true)) {
            this.hideUpdateDrafts();
            setTimeout(() => {
              this.showUpdateDrafts();
            }, 5000);
          }
          this.changesMade = false;
          await this.fetchRecruitmentCampaigns();
          if (submit === true) {
            this.closeModal();
            this.clearMetadata();
            this.updateClosingModal(false);
          }
        } catch (err) {
          this.$notify({
            title: 'ERROR',
            text: `Unable to ${submit === true ? 'submit' : 'save'} Recruitment Campaign!`,
            type: 'error',
            duration: 5000,
          });
        } finally {
          this.saving = false;
        }
      } else {
        this.$notify({
          title: 'ERROR',
          text: `Brand is required!`,
          type: 'error',
          duration: 5000,
        });
      }
    },
    getLanguageId(isoCode: string): number {
      let id = 0;
      this.languages.forEach((language: ILanguage) => {
        if (language?.isoCode === isoCode) {
          id = language?.languageId;
        }
      });
      return id;
    },
    updatePrompt(): void {
      this.formData.markAllAsTouched();
      this.validateControl('headline');
      this.validateControl('description');
      this.validateControl('twitterDesc');
      this.validateControl('instagramDesc');
      this.validateControl('facebookDesc');
      this.checkLanguageErrors();
      this.attemptedSubmit = true;
      let languageErrors = false;
      this.languages?.forEach((language: any) => {
        if (language?.hasErrors) {
          languageErrors = true;
        }
      });
      if (!this.formData.hasErrors && !languageErrors) {
        this.openPrompt('update');
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Recruitment Campaign form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      const publishByDate = this.formData?.controls?.publishDate?.value ?? '';
      const fromDate = this.formData?.controls?.startDate?.value ?? '';
      const toDate = this.formData?.controls?.endDate?.value ?? '';

      let notes = '';
      if (this.formData?.controls?.notes?.value) {
        let items = [...this.formData?.controls?.notes?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          notes += `${el.language}=${el.value}::: `;
        });
        notes = notes.slice(0, notes.length - 4);
      }
      let headline = '';
      if (this.formData?.controls?.headline?.value) {
        let items = [...this.formData?.controls?.headline?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          headline += `${el.language}=${el.value}::: `;
        });
        headline = headline.slice(0, headline.length - 4);
      }
      let longDescription = '';
      if (this.formData?.controls?.description?.value) {
        let items = [...this.formData?.controls?.description?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          longDescription += `${el.language}=${el.value}::: `;
        });
        longDescription = longDescription.slice(0, longDescription.length - 4);
      }
      let facebookDesc = '';
      if (this.formData?.controls?.facebookDesc?.value) {
        let items = [...this.formData?.controls?.facebookDesc?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          facebookDesc += `${el.language}=${el.value}::: `;
        });
        facebookDesc = facebookDesc.slice(0, facebookDesc.length - 4);
      }
      let twitterDesc = '';
      if (this.formData?.controls?.twitterDesc?.value) {
        let items = [...this.formData?.controls?.twitterDesc?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          twitterDesc += `${el.language}=${el.value}::: `;
        });
        twitterDesc = twitterDesc.slice(0, twitterDesc.length - 4);
      }
      let instagramDesc = '';
      if (this.formData?.controls?.instagramDesc?.value) {
        let items = [...this.formData?.controls?.instagramDesc?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          instagramDesc += `${el.language}=${el.value}::: `;
        });
        instagramDesc = instagramDesc.slice(0, instagramDesc.length - 4);
      }
      const payload = {
        recruitmentCampaignTypeId: this.formData?.controls?.jobType?.value,
        description: this.formData?.controls?.campaignName?.value,
        facebookDescription: facebookDesc,
        twitterDescription: twitterDesc,
        instagramDescription: instagramDesc,
        notes: notes,
        companyId: this.formData?.controls?.company?.value,
        brandId: this.formData?.controls?.brand?.value,
        createdByUserId: this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId,
        headline: headline,
        longDescription: longDescription,
        publishByDate: publishByDate,
        fromDate: fromDate,
        toDate: toDate,
        contactUrl: this.formData?.controls?.contactUrl?.value,
        sameDescription: this.formData?.controls?.sameDescription?.value,
        // sendToSocialContacts: this.formData?.controls?.socialContacts?.value,
        statusId: this.campaign?.statusId,
        storeIds: this.selectedLocationIds,
        images: this.formData?.controls?.images?.value,
        hasPrimaryImageChange: this.hasPrimaryImageChange,
        backgroundBlurry: this.backgroundBlurry,
        timezone: this.timezone?.value,
        languageSpecificImagesRequired: this.formData?.controls?.languageSpecificImagesRequired?.value ?? true,
        tagIds: this.recruitmentCampaignTagValues.map((tag) => tag.value),
        channels: this.campaignChannelValues.map((channel) => channel.value),
      };
      this.saving = true;
      try {
        await this.updateRecruitmentCampaign({ body: payload, campaignId: this.campaign?.recruitmentCampaignId });
        this.$notify({
          title: 'SUCCESS',
          text: `Recruitment Campaign updated successfully!`,
          type: 'success',
          duration: 5000,
        });
        await this.fetchRecruitmentCampaigns();
        this.closePrompt();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: `Unable to update Recruitment Campaign!`,
          type: 'error',
          duration: 5000,
        });
      } finally {
        this.saving = false;
      }
    },
    submitPrompt(): void {
      this.formData.markAllAsTouched();
      this.validateControl('headline');
      this.validateControl('description');
      this.validateControl('twitterDesc');
      this.validateControl('instagramDesc');
      this.validateControl('facebookDesc');
      this.checkLanguageErrors();
      this.attemptedSubmit = true;
      let languageErrors = false;
      this.languages?.forEach((language: any) => {
        if (language?.hasErrors) {
          languageErrors = true;
        }
      });
      if (!this.formData.hasErrors && !languageErrors) {
        this.openPrompt('submit');
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Recruitment Campaign form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async duplicate(): Promise<void> {
      this.duplicating = true;
      const publishByDate = null;
      const fromDate = null;
      const toDate = null;
      let notes = '';
      if (this.formData?.controls?.notes?.value) {
        this.formData?.controls?.notes?.value?.forEach((el: any) => {
          notes += `${el.language}=${el.value}::: `;
        });
        notes = notes.slice(0, notes.length - 4);
      }
      let headline = '';
      if (this.formData?.controls?.headline?.value) {
        this.formData?.controls?.headline?.value?.forEach((el: any) => {
          headline += `${el.language}=${el.value}::: `;
        });
        headline = headline.slice(0, headline.length - 4);
      }
      let longDescription = '';
      if (this.formData?.controls?.description?.value) {
        this.formData?.controls?.description?.value?.forEach((el: any) => {
          longDescription += `${el.language}=${el.value}::: `;
        });
        longDescription = longDescription.slice(0, longDescription.length - 4);
      }
      let facebookDesc = '';
      if (this.formData?.controls?.facebookDesc?.value) {
        this.formData?.controls?.facebookDesc?.value?.forEach((el: any) => {
          facebookDesc += `${el.language}=${el.value}::: `;
        });
        facebookDesc = facebookDesc.slice(0, facebookDesc.length - 4);
      }
      let twitterDesc = '';
      if (this.formData?.controls?.twitterDesc?.value) {
        this.formData?.controls?.twitterDesc?.value?.forEach((el: any) => {
          twitterDesc += `${el.language}=${el.value}::: `;
        });
        twitterDesc = twitterDesc.slice(0, twitterDesc.length - 4);
      }
      let instagramDesc = '';
      if (this.formData?.controls?.instagramDesc?.value) {
        this.formData?.controls?.instagramDesc?.value?.forEach((el: any) => {
          instagramDesc += `${el.language}=${el.value}::: `;
        });
        instagramDesc = instagramDesc.slice(0, instagramDesc.length - 4);
      }
      const payload = {
        recruitmentCampaignTypeId: this.formData?.controls?.jobType?.value,
        description: this.formData?.controls?.campaignName?.value,
        facebookDescription: facebookDesc,
        twitterDescription: twitterDesc,
        instagramDescription: instagramDesc,
        notes: notes,
        companyId: this.formData?.controls?.company?.value,
        brandId: this.formData?.controls?.brand?.value,
        createdByUserId: this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId,
        headline: headline,
        longDescription: longDescription,
        publishByDate: publishByDate,
        fromDate: fromDate,
        toDate: toDate,
        contactUrl: this.formData?.controls?.contactUrl?.value,
        sameDescription: this.formData?.controls?.sameDescription?.value,
        // sendToSocialContacts: this.formData?.controls?.socialContacts?.value,
        statusId: 1,
        storeIds: this.selectedLocationIds,
        images: this.formData?.controls?.images?.value,
        backgroundBlurry: this.backgroundBlurry ?? true,
        timezone: this.timezone?.value,
        languageSpecificImagesRequired: this.formData?.controls?.languageSpecificImagesRequired?.value ?? true,
        tagIds: this.recruitmentCampaignTagValues.map((tag) => tag.value),
        channels: this.campaignChannelValues.map((channel) => channel.value),
      };
      try {
        const response = await this.saveRecruitmentCampaign(payload);
        this.$notify({
          title: 'SUCCESS',
          text: `Recruitment Campaign duplicated successfully!`,
          type: 'success',
          duration: 5000,
        });
        this.changesMade = false;
        await this.fetchRecruitmentCampaigns();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
        setTimeout(() => {
          this.updateModalComponent('recruitment-campaign');
          this.updateModalType('large');
          this.updateModalData({ campaignId: response?.recruitmentCampaignId, row: response, mode: 'edit' });
          this.openModal();
        }, 500);
        this.duplicating = false;
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: `Unable to duplicate Recruitment Campaign!`,
          type: 'error',
          duration: 5000,
        });
        this.duplicating = false;
      }
    },
    preview(): void {
      this.previewOpen = !this.previewOpen;
    },
    toggleChanges(): void {
      this.changesMade = !this.changesMade;
    },
    toggleFormValid(): void {
      this.formValid = !this.formValid;
    },
    async resizeImages(): Promise<void> {
      if (this.languageSpecificImagesRequired) {
        for (let language of this.languages) {
          await this.generateImagesHelper([], [language.languageId]);
        }
      } else {
        await this.generateImagesHelper(
          [],
          this.languages.map((el: ILanguage) => el.languageId),
        );
      }

      this.imageResizeRequired = false;
    },
    isMallAdmin(): boolean {
      return this.user.role?.roleId === Role.MallAdmin;
    },
    campaignChannelsUpdated(value: any): void {
      this.campaignChannelValues = value;
      this.formData.controls.channels.value = value;
      this.channelsTouched = true;
      this.setChangesMade();
    },
  },
});
