
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../atoms/Input/Input.vue';
import Dropdown from '../../../../atoms/Dropdown/Dropdown.vue';
import MultiSelectDropdown from '../../../../molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import SingleSelectInputSuggestion from '../../../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import Button from '../../../../atoms/Button/Button.vue';
import Modal from '../../../../atoms/Modal/Modal.vue';
import Tooltip from '../../../../atoms/Tooltip/Tooltip.vue';
import { FormBuilder, FormControl } from '../../../../../utilities/Forms';
import { Validators } from '../../../../../utilities/Validators/Validators';
import { IDropdownOption } from '../../../../../utilities/Interfaces/form.interfaces';
import Divider from '../../../../atoms/Divider/Divider.vue';
import HttpService from '../../../../../services/HttpService';
import { AxiosResponse } from 'axios';
import Search from '../../../../atoms/Search/Search.vue';
import ItemSelector from '@/atomic/molecules/Item-Selector/Item-Selector.vue';

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-search': Search,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-button': Button,
    'arc-modal': Modal,
    'arc-tooltip': Tooltip,
    'arc-divider': Divider,
    'arc-item-selector': ItemSelector,
  },

  computed: {
    ...mapGetters(['modalData', 'user', 'access', 'permissions', 'contactTypes', 'communicationTypes', 'channels', 'mallOptions', 'loadingMalls']),
    containerType(): string {
      return this.permissions?.superAdminAccess ? 'admin-container' : 'user-container';
    },
    updateDisabled(): boolean {
      return !this.permissions?.superAdminAccess;
    },
    deleteDisabled(): boolean {
      return !this.permissions?.superAdminAccess;
    },
    filteredMalls(): any {
      let malls = this.mallOptions;
      
      // filter search params
      if (this.searchFilter !== '') {
        let searchResults: any[] = [];
        malls.forEach((mall: any) => {
          if (mall.description.toLowerCase().includes(this.searchFilter.toLowerCase())) {
            searchResults.push(mall);
          }
        });
        malls = searchResults;
      }
      malls = malls?.sort((a: any, b: any) =>
        a.description.toString().localeCompare(b.description.toString(), undefined, { numeric: true }),
      );
      return malls;
    },
    filteredMallsAvailable(): any {
      return this.filteredMalls.filter((mall: any) => !this.selectedMallIds?.includes(mall?.value));
    },
    filteredMallsSelected(): any {
      return this.filteredMalls.filter((mall: any) => this.selectedMallIds?.includes(mall?.value));
    },
  },

  async mounted(): Promise<void> {
    if (this.modalData?.row) {
      new HttpService().getContactGroup(this.modalData?.row?.id).then((results: AxiosResponse<any>) => {
        this.modalData.row = results?.data?.data;
      });
    }
    await this.loadContactTypes('all');
    await this.loadChannels();
    await this.loadStoreCommunicationTypes();
    await this.setForm();
    await this.loadMallOptions();
    this.loaded = true;
  },

  data(): {
    status: string;
    changesMade: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    saving: boolean;
    deleting: boolean;
    formData: any;
    loaded: boolean;
    allowShowOptions: boolean;
    attemptedSubmit: boolean;
    contactTypeValue: IDropdownOption | null;
    emailTypeValue: IDropdownOption | null;
    emailTypes: IDropdownOption[];
    commTypeValues: IDropdownOption[] | [];
    channelValues: IDropdownOption[] | [];
    searchFilter: string;
    selectedMallIds: string[];
  } {
    return {
      status: 'Draft',
      changesMade: false,
      formValid: true,
      promptOpen: false,
      promptType: 'save',
      saving: false,
      deleting: false,
      formData: null,
      loaded: false,
      allowShowOptions: false,
      attemptedSubmit: false,
      contactTypeValue: null,
      emailTypeValue: null,
      emailTypes: [
        { value: 'to', description: 'TO' },
        { value: 'cc', description: 'CC' },
      ],
      commTypeValues: [],
      channelValues: [],
      searchFilter: '',
      selectedMallIds: [],
    };
  },

  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'saveContact',
      'updateContact',
      'deleteContact',
      'resetPagination',
      'loadContactTypes',
      'loadChannels',
      'loadStoreCommunicationTypes',
      'loadMallOptions',
    ]),
    async setForm() {
      // initializers
      let firstName = '';
      let lastName = '';
      let title = '';
      let phoneNumber = '';
      let email = '';
      let aliasEmail = '';
      let contactType = '';
      let actionRequired = true;
      let emailType: any = null;
      let malls = [];
      let communicationTypes: any = [];
      let channels = [];
      let contactIds = [];
      let contactGroupId = '';

      // set values if editing existing data
      if (this.modalData?.row) {
        firstName = this.modalData?.row?.firstName;
        lastName = this.modalData?.row?.lastName;
        title = this.modalData?.row?.title;
        phoneNumber = this.modalData?.row?.phoneNumber;
        email = this.modalData?.row?.email.trim();
        aliasEmail = this.modalData?.row?.aliasEmail?.trim();
        contactType = this.modalData?.row?.contactType;
        actionRequired = this.modalData?.row?.actionRequired;
        emailType = this.modalData?.row?.emailType;
        malls = this.modalData?.row?.malls;
        communicationTypes = this.modalData?.row?.communicationTypes;
        channels = this.modalData?.row?.channels;
        contactIds = this.modalData?.row?.contactIds;
        contactGroupId = this.modalData?.row?.id;

        this.selectedMallIds = this.modalData?.row?.malls?.map((mall: any) => mall.mallId);
      }
      // set form
      this.formData = new FormBuilder({
        firstName: new FormControl(firstName),
        lastName: new FormControl(lastName),
        title: new FormControl(title),
        phoneNumber: new FormControl(phoneNumber),
        email: new FormControl(email.trim(), [Validators.email, Validators.required]),
        aliasEmail: new FormControl(aliasEmail?.trim()),
        contactType: new FormControl(contactType, [Validators.required]),
        actionRequired: new FormControl(actionRequired),
        emailType: new FormControl(emailType, [Validators.required]),
        malls: new FormControl(malls),
        communicationTypes: new FormControl(communicationTypes),
        channels: new FormControl(channels),
        contactIds: new FormControl(contactIds),
        contactGroupId: new FormControl(contactGroupId),
      });

      this.emailTypes.forEach((type: IDropdownOption) => {
        if (emailType === type.value) {
          this.emailTypeValue = type;
        }
      });
    },
    updateContactType(value: IDropdownOption) {
      this.contactTypeValue = value;
      this.formData.controls['contactType'].value = [value];
      this.formData.controls['contactType'].validate();
      this.setChangesMade('contact type');
    },
    updateEmailType(value: IDropdownOption) {
      this.emailTypeValue = value;
      this.formData.controls['emailType'].value = [value];
      this.formData.controls['emailType'].validate();
      this.setChangesMade('email type');
    },
    commTypesUpdated(value: any): void {
      this.commTypeValues = value;
      this.formData.controls['communicationTypes'].value = [value];
      this.formData.controls['communicationTypes'].validate();
      this.setChangesMade('comm types');
    },
    channelsUpdated(value: any): void {
      this.channelValues = value;
      this.formData.controls['channels'].value = value;
      this.formData.controls['channels'].validate();
      this.setChangesMade('channels');
    },
    setChangesMade(type?: string) {
      this.changesMade = true;
    },
    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade();
        }
      }, 0);
    },
    closePrompt(): void {
      this.promptOpen = false;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    async delete(): Promise<void> {
      this.deleting = true;
      this.promptOpen = false;
      try {
        await this.deleteContact(this.modalData?.row?.id);
        this.promptOpen = false;
        this.resetPagination();
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
          this.$notify({
            title: 'SUCCESS',
            text: 'Contact deleted successfully!',
            type: 'success',
            duration: 5000,
          });
        }, 500);
      } catch (err) {
        this.promptOpen = false;
        this.$notify({
          title: 'ERROR',
          text: 'Unable to delete contact.',
          type: 'warn',
          duration: 5000,
        });
      } finally {
        this.deleting = false;
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    async save(): Promise<void> {
      this.attemptedSubmit = true;
      this.formData.markAllAsTouched();

      if (!this.formData.hasErrors) {
        let payload = {
          firstName: this.formData?.controls?.firstName?.value,
          lastName: this.formData?.controls?.lastName?.value,
          title: this.formData?.controls?.title?.value,
          phoneNumber: this.formData?.controls?.phoneNumber?.value,
          email: this.formData?.controls?.email?.value?.trim().toLowerCase(),
          contactTypeId: this.contactTypeValue?.value,
          actionRequired: this.formData?.controls?.actionRequired.value,
          emailType: this.emailTypeValue?.value,
          mallIds: this.selectedMallIds,
          communicationTypes: this.commTypeValues.map((el) => {
            const communicationType = {};
            communicationType['communicationTypeId'] = el.value;
            communicationType['actionRequired'] = this.formData?.controls?.actionRequired.value;
            communicationType['notificationOnly'] = !this.formData?.controls?.actionRequired.value;
            return communicationType;
          }),
          channelIds: this.channelValues.map((el) => el.value),
          contactIds: this.formData?.controls?.contactIds.value,
        };

        this.saving = true;
        try {
          await this.saveContact(payload);
          this.$notify({
            title: 'SUCCESS',
            text: 'Contact saved successfully!',
            type: 'success',
            duration: 5000,
          });
          this.resetPagination();
          this.changesMade = false;
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          let errorText = 'Unable to create Contact';
          if (err === 'The resource you are trying to create already exists.') {
            errorText = 'There is already a Contact associated with that email.';
          } else if (err === 'Invalid email address') {
            errorText = 'Invalid email address';
          }
          this.$notify({
            title: 'ERROR',
            text: errorText,
            type: 'error',
            duration: 5000,
          });
        } finally {
          this.saving = false;
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Contact form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      this.attemptedSubmit = true;
      this.formData.markAllAsTouched();

      if (!this.formData.hasErrors) {
        let payload = {
          firstName: this.formData?.controls?.firstName?.value,
          lastName: this.formData?.controls?.lastName?.value,
          title: this.formData?.controls?.title?.value,
          phoneNumber: this.formData?.controls?.phoneNumber?.value,
          email: this.formData?.controls?.email?.value?.trim().toLowerCase(),
          contactTypeId: this.contactTypeValue?.value,
          actionRequired: this.formData?.controls?.actionRequired.value,
          emailType: this.emailTypeValue?.value,
          mallIds: this.selectedMallIds,
          communicationTypes: this.commTypeValues.map((el) => {
            const communicationType = {};
            communicationType['communicationTypeId'] = el.value;
            communicationType['actionRequired'] = this.formData?.controls?.actionRequired.value;
            communicationType['notificationOnly'] = !this.formData?.controls?.actionRequired.value;
            return communicationType;
          }),
          channelIds: this.channelValues.map((el) => el.value),
          contactIds: this.formData?.controls?.contactIds.value,
        };

        this.saving = true;

        try {
          await this.updateContact({ payload, id: this.modalData?.row?.id });
          this.$notify({
            title: 'SUCCESS',
            text: 'Contact updated successfully!',
            type: 'success',
            duration: 5000,
          });
          this.resetPagination();
          this.changesMade = false;
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          let errorText = 'Unable to update Contact';
          if (err === 'Invalid email address') {
            errorText = 'Invalid email address';
          }
          this.$notify({
            title: 'ERROR',
            text: errorText,
            type: 'error',
            duration: 5000,
          });
        } finally {
          this.saving = false;
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Contact form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    updateSearchMallsFilter(value: string) {
      this.searchFilter = value;
    },
    mallInfo(mall: any): string {
      return `${mall.description}`;
    },
    mallAdded(value: any): void {
      this.formData.controls.malls.value.push(value);
      this.selectedMallIds.push(value.value);
      this.formData.controls.malls.value.sort((a: any, b: any) =>
        a.description.toString().localeCompare(b.description.toString(), undefined, { numeric: true }),
      );
      this.setChangesMade('MALL ADDED');
    },
    mallRemoved(value: any): void {
      const index = this.formData.controls.malls.value.findIndex((item: any) => item.value === value.value);
      this.formData.controls.malls.value.splice(index, 1);
      const idx = this.selectedMallIds.indexOf(value.value || value.mallId);
      if (idx > -1) {
        this.selectedMallIds.splice(idx, 1);
      }
      this.setChangesMade('MALL REMOVED');
    },
    addAllMalls(): void {
      this.filteredMallsAvailable.forEach((mall: any) => {
        this.mallAdded(mall);
      });
    },
    removeAllMalls(): void {
      this.filteredMallsSelected.forEach((mall: any) => {
        this.mallRemoved(mall);
      });
    },
  },
  watch: {
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
    contactTypes: {
      deep: true,
      handler() {
        this.contactTypes.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.contactTypeId === item?.value) {
            this.contactTypeValue = item;
          }
        });
      },
    },
    communicationTypes: {
      deep: true,
      handler() {
        let ct: IDropdownOption[] = [];
        this.communicationTypes.forEach((type: IDropdownOption) => {
          if (this.modalData?.row?.communicationTypes) {
            this.modalData?.row?.communicationTypes.forEach((t: any) => {
              if (typeof t === 'object') {
                if (type.value === t.communicationTypeId) {
                  ct.push(type);
                }
              }
              if (typeof t === 'number') {
                if (type.value === t) {
                  ct.push(type);
                }
              }
            });
          }
        });
        this.commTypeValues = ct;
      },
    },
    channels: {
      deep: true,
      handler() {
        let ch: IDropdownOption[] = [];
        this.channels.forEach((channel: IDropdownOption) => {
          if (this.modalData?.row?.channels) {
            this.modalData?.row?.channels.forEach((c: any) => {
              if (channel.value === c.channelId) {
                ch.push(channel);
              }
            });
          }
        });
        this.channelValues = ch;
      },
    }
  },
});
